
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Unit 5',
        id: 'WB6-U5-P41-E1',
        audio: '',
        video: '',
        component: WordsBox,
        isAutofocus: true,
        typeInput: 'center',
        paddingLeft: 7,
        fontSize: 30,
        exerciseKey: 'img/FriendsPlus/Page41/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page41/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page41/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page41/E1/3.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page41/E1/4.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page41/E1/5.jpg', input: true, answer: "s" },
                { url: 'img/FriendsPlus/Page41/E1/6.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page41/E1/7.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page41/E1/8.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page41/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page41/E1/10.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page41/E1/11.jpg', input: true, answer: "x" },
                { url: 'img/FriendsPlus/Page41/E1/12.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page41/E1/13.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page41/E1/14.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page41/E1/15.jpg' },
                { url: 'img/FriendsPlus/Page41/E1/16.jpg', input: true, answer: "h" },
                { url: 'img/FriendsPlus/Page41/E1/17.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page41/E1/18.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page41/E1/19.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page41/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page41/E1/21.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page41/E1/22.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page41/E1/23.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page41/E1/24.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page41/E1/25.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page41/E1/26.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page41/E1/27.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page41/E1/28.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 5',
        id: 'WB6-U5-P41-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page41/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page41/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page41/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/3.jpg', input: true, answer: "First" },
                { url: 'img/FriendsPlus/Page41/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page41/E2/5.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/6.jpg', input: true, answer: "can" },
                { url: 'img/FriendsPlus/Page41/E2/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page41/E2/8.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/9.jpg', input: true, answer: "or" },
                { url: 'img/FriendsPlus/Page41/E2/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page41/E2/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page41/E2/12.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/13.jpg', input: true, answer: "After" },
                { url: 'img/FriendsPlus/Page41/E2/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page41/E2/15.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/16.jpg', input: true, answer: "with" },
                { url: 'img/FriendsPlus/Page41/E2/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page41/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/19.jpg', input: true, answer: "got" },
                { url: 'img/FriendsPlus/Page41/E2/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page41/E2/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page41/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/23.jpg', input: true, answer: "Next" },
                { url: 'img/FriendsPlus/Page41/E2/24.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page41/E2/25.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page41/E2/26.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/27.jpg', input: true, answer: "types" },
                { url: 'img/FriendsPlus/Page41/E2/28.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page41/E2/29.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page41/E2/30.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/31.jpg', input: true, answer: "Finally" },
                { url: 'img/FriendsPlus/Page41/E2/32.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page41/E2/33.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page41/E2/34.jpg' },
                { url: 'img/FriendsPlus/Page41/E2/35.jpg', input: true, answer: "your" },
                { url: 'img/FriendsPlus/Page41/E2/36.jpg' },
            ],
            [
                // Column17
                { url: 'img/FriendsPlus/Page41/E2/37.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 5',
        id: 'WB6-U5-P41-E3',
        audio: '',
        video: '',
        fontSize: 22,
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page41/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page41/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/3.jpg', input: true, answer: "Italy" },
                { url: 'img/FriendsPlus/Page41/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/7.jpg', input: true, answer: "cold " },
                { url: 'img/FriendsPlus/Page41/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/11.jpg', input: true, answer: "salad" },
                { url: 'img/FriendsPlus/Page41/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/13.jpg', input: true, answer: "(Italian) cheese/cheese" },
                { url: 'img/FriendsPlus/Page41/E3/14.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/16.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/17.jpg', input: true, answer: "pizza" },
                { url: 'img/FriendsPlus/Page41/E3/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/19.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/20.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/21.jpg', input: true, answer: "vegetables" },
                { url: 'img/FriendsPlus/Page41/E3/22.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/23.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/24.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/25.jpg', input: true, answer: "(Italian) meat/meat" },
                { url: 'img/FriendsPlus/Page41/E3/26.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/27.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/28.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/29.jpg', input: true, answer: "famous" },
                { url: 'img/FriendsPlus/Page41/E3/30.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/31.jpg', input: true, answer: "ice cream" },
                { url: 'img/FriendsPlus/Page41/E3/32.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/33.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page41/E3/34.jpg' },
                { url: 'img/FriendsPlus/Page41/E3/35.jpg', input: true, answer: "place" },
                { url: 'img/FriendsPlus/Page41/E3/36.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 5',
        id: 'WB6-U5-P41-E4',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: '',
        titleQuestion: [{ num: '4', title: 'Imagine you go to a perfect café. Write a review for your food blog. Use the text in exercise 3 to help you.', color: "#00A851", star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;