import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';

import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P64-E1',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page64/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page64/E1/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>Yasmin / interested in art ✘</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Yasmin isn’t interested in art.</u></p>" +

          "<p><span style='font-weight:600'>1</span>. I / from the UK ✔</p><p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>2</span>. William and Lewis / brothers ✔</p><p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>3</span>. you / into video games ✘</p><p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>4</span>. David / good at football ✘</p><p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>5</span>. we / fourteen ✘</p><p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>6</span>. they / into skateboarding ✔</p><p style='font-weight:500'>#</p>",

        answer: [
          "I'm from the UK.",
          "William and Lewis are brothers.",
          "You aren't into video games.",
          "David isn't good at football.",
          "We aren't fourteen.",
          "They're into skateboarding.",

        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P64-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page64/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page64/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page64/E2/3.jpg', input: true, answer: "They" },
        { url: 'img/FriendsPlus/Page64/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page64/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page64/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page64/E2/7.jpg', input: true, answer: "She" },
        { url: 'img/FriendsPlus/Page64/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page64/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page64/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page64/E2/11.jpg', input: true, answer: "We" },
        { url: 'img/FriendsPlus/Page64/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page64/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page64/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page64/E2/15.jpg', input: true, answer: "You" },
        { url: 'img/FriendsPlus/Page64/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page64/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page64/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page64/E2/19.jpg', input: true, answer: "It" },
        { url: 'img/FriendsPlus/Page64/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page64/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page64/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page64/E2/23.jpg', input: true, answer: "He" },
        { url: 'img/FriendsPlus/Page64/E2/24.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: {
    id: 'WB6-LFP-P64-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E3/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page64/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page64/E3/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page64/E3/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page64/E3/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page64/E3/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/19.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page64/E3/21.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page64/E3/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/24.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page64/E3/25.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/26.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page64/E3/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/30.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page64/E3/31.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/32.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page64/E3/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/36.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page64/E3/37.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page64/E3/38.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page64/E3/40.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P64-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page64/E4/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page64/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page64/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page64/E4/3.jpg', input: true, answer: "us" },
        { url: 'img/FriendsPlus/Page64/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page64/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page64/E4/6.jpg', input: true, answer: "them" },
        { url: 'img/FriendsPlus/Page64/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page64/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page64/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page64/E4/10.jpg', input: true, answer: "him" },
        { url: 'img/FriendsPlus/Page64/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page64/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page64/E4/13.jpg', input: true, answer: "me" },
        { url: 'img/FriendsPlus/Page64/E4/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page64/E4/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page64/E4/16.jpg' },
        { url: 'img/FriendsPlus/Page64/E4/17.jpg', input: true, answer: "her" },
        { url: 'img/FriendsPlus/Page64/E4/18.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P64-E5',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page64/E5/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page64/E5/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>This is Davids favourite book.</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>This is David’s favourite book.</u></p>" +

          "<p><span style='font-weight:600'>1</span>.  Where is Marys notebook? # </p>" +
          "<p><span style='font-weight:600'>2</span>.  This is the childrens classroom. # </p>" +
          "<p><span style='font-weight:600'>3</span>.  What are the teachers names? # </p>" +
          "<p><span style='font-weight:600'>4</span>.  I like Marias photos. # </p>",

        answer: [
          "Where is Mary's notebook?",
          "This is the children's classroom.",
          "What are the teachers' names?",
          "I like Maria's photos.",

        ],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P64-E6',
    audio: '',
    video: '',
    component: MC2,
    widthButton: 100,
    marginLeft: 200,
    exerciseKey: 'img/FriendsPlus/Page64/E6/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Choose the correct answers.', color: '#3B3074' }],
    titleImage: 'img/FriendsPlus/Page64/E6/title.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'These are our holiday photos. They’re ....... .',
        answers: [
          { image: "", isCorrect: true, right: "", text: "ours" },
          { image: "", isCorrect: false, right: "", text: "yours" },
          { image: "", isCorrect: false, right: "", text: "theirs" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'This is my new hat. It’s ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "hers" },
          { image: "", isCorrect: true, right: "", text: "mine" },
          { image: "", isCorrect: false, right: "", text: "yours" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'Yesterday I ....... my friends in town and we went to the café.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "hers" },
          { image: "", isCorrect: false, right: "", text: "mine" },
          { image: "", isCorrect: false, right: "", text: "his" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'That’s my cousins’ house. It’s ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "his" },
          { image: "", isCorrect: true, right: "", text: "theirs" },
          { image: "", isCorrect: false, right: "", text: "ours" },
        ],
        no: 4,
        question: '',
        type: '',
      },
    ]
  },
  7: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P64-E7',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page64/E7/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page64/E7/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page64/E7/2.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/3.jpg', input: true, answer: "Are" },
        { url: 'img/FriendsPlus/Page64/E7/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page64/E7/5.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/6.jpg', input: true, answer: "aren't" },
        { url: 'img/FriendsPlus/Page64/E7/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page64/E7/8.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/9.jpg', input: true, answer: "Is" },
        { url: 'img/FriendsPlus/Page64/E7/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page64/E7/11.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/12.jpg', input: true, answer: "isn't" },
        { url: 'img/FriendsPlus/Page64/E7/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page64/E7/14.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/15.jpg', input: true, answer: "Are" },
        { url: 'img/FriendsPlus/Page64/E7/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page64/E7/17.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/18.jpg', input: true, answer: "are" },
        { url: 'img/FriendsPlus/Page64/E7/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page64/E7/20.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/21.jpg', input: true, answer: "Is" },
        { url: 'img/FriendsPlus/Page64/E7/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page64/E7/23.jpg' },
        { url: 'img/FriendsPlus/Page64/E7/24.jpg', input: true, answer: "is" },
        { url: 'img/FriendsPlus/Page64/E7/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



}

export default json;