import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'WB6-U8-P59-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page59/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person's favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page59/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page59/E1/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page59/E1/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/17.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page59/E1/18.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/21.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/22.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page59/E1/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/26.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page59/E1/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/29.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/32.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/33.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page59/E1/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/35.jpg' },
      ]

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P59-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page59/E2/Key/Key.png',
    imgSize: '80%',
    inputSize: 430,
    titleImage: 'img/FriendsPlus/Page59/E2/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: "#de2f3a", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>We’re running &#160;&#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> because we’re late. </u></p>" +
          "<p><span style='font-weight:600'>1 </span>My dad isn't at work # .</p>" +
          "<p><span style='font-weight:600'>2 </span>I never go to that sports shop # .</p>" +
          "<p><span style='font-weight:600'>3 </span>We're making some sandwiches # .</p>" +
          "<p><span style='font-weight:600'>4 </span>I've got a phrasebook # .</p>" +
          "<p><span style='font-weight:600'>5 </span>John can swim fast # .</p>" +
          "<p><span style='font-weight:600'>6 </span>I'm going to open the window # .</p>" +
          "<p><span style='font-weight:600'>7 </span>I always enjoy geography # .</p>" +
          "<p><span style='font-weight:600'>8 </span>We loved that new film # .</p>",
        answer: [
          "because he's ill",
          "because it's very expensive",
          "because we're hungry",
          "because I can't speak Italian",
          "because he's very fit",
          "because I'm really hot",
          "because it's an interesting subject",
          "because it was really exciting",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P59-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page59/E3/Key/Key.png',
    component: D1,
    fontSize: 21,
    titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page59/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/6.jpg', input: true, answer: "plans" },
        { url: 'img/FriendsPlus/Page59/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/9.jpg', input: true, answer: "Friday evening" },
        { url: 'img/FriendsPlus/Page59/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/12.jpg', input: true, answer: "6.30" },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/14.jpg', input: true, answer: "going to drive" },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/17.jpg', input: true, answer: "an hour" },
        { url: 'img/FriendsPlus/Page59/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/21.jpg', input: true, answer: "going to stay" },
        { url: 'img/FriendsPlus/Page59/E3/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/25.jpg', input: true, answer: "small house near the sea" },
        { url: 'img/FriendsPlus/Page59/E3/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/28.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/29.jpg', input: true, answer: "Saturday" },
        { url: 'img/FriendsPlus/Page59/E3/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/31.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/32.jpg', input: true, answer: "going to spend time" },
        { url: 'img/FriendsPlus/Page59/E3/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/35.jpg', input: true, answer: "trunks" },
        { url: 'img/FriendsPlus/Page59/E3/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/38.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/39.jpg', input: true, answer: "Sunday" },
        { url: 'img/FriendsPlus/Page59/E3/40.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/41.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/42.jpg', input: true, answer: "my dad's small boat" },
        { url: 'img/FriendsPlus/Page59/E3/43.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/44.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/45.jpg', input: true, answer: "waterproofs" },
        { url: 'img/FriendsPlus/Page59/E3/46.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/47.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/48.jpg' },
        { url: 'img/FriendsPlus/Page59/E3/49.jpg', input: true, answer: "8.00" },
        { url: 'img/FriendsPlus/Page59/E3/50.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E3/51.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P59-E4',
    audio: '',
    video: '',
    component: T2,
    // exerciseKey: 'img/FriendsPlus/Page59/E3/Key/Key.png',
    // imgSize: '85%',
    // titleImage: 'img/FriendsPlus/Page59/E3/title.jpg',
    titleQuestion: [{ num: '4', title: "Imagine Jim is staying with Charlie's family. Jim wants to send a POSTCARD to his parents, Sam and Joshua. Help him design a nice postcard and write some words on it.", color: "#2ead59", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [

        ],
        type: 'longAnwser'
      },
    ]
  },

}
export default json;