
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox2';


const json = {

  1: {
    unit: 'Unit 5',
    id: 'WB6-U5-P36-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page36/E1/Key/answerKey.png',
    component: D1,
    fontSize: 21,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page36/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/3.jpg', input: true, answer: "chicken" },
        { url: 'img/FriendsPlus/Page36/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/5.jpg', input: true, answer: "eggs" },
        { url: 'img/FriendsPlus/Page36/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/9.jpg', input: true, answer: "soup" },
        { url: 'img/FriendsPlus/Page36/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/11.jpg', input: true, answer: "crisps" },
        { url: 'img/FriendsPlus/Page36/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/13.jpg', input: true, answer: "fish" },
        { url: 'img/FriendsPlus/Page36/E1/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/17.jpg', input: true, answer: "burgers" },
        { url: 'img/FriendsPlus/Page36/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/19.jpg', input: true, answer: "juice" },
        { url: 'img/FriendsPlus/Page36/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/21.jpg', input: true, answer: "cheese" },
        { url: 'img/FriendsPlus/Page36/E1/22.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: {
    unit: 'Unit 5',
    id: 'WB6-U5-P36-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page36/E2/Key/Key.png',
    component: D1,
    // fontSize: 21,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page36/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/3.jpg', input: true, answer: "nuts" },
        { url: 'img/FriendsPlus/Page36/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/7.jpg', input: true, answer: "apple" },
        { url: 'img/FriendsPlus/Page36/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/10.jpg', input: true, answer: "sandwiches" },
        { url: 'img/FriendsPlus/Page36/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/14.jpg', input: true, answer: "meat" },
        { url: 'img/FriendsPlus/Page36/E2/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/18.jpg', input: true, answer: "salad" },
        { url: 'img/FriendsPlus/Page36/E2/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/21.jpg', input: true, answer: "fizzy drinks" },
        { url: 'img/FriendsPlus/Page36/E2/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/24.jpg', input: true, answer: "juice" },
        { url: 'img/FriendsPlus/Page36/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/27.jpg', input: true, answer: "water" },
        { url: 'img/FriendsPlus/Page36/E2/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/31.jpg', input: true, answer: "pasta" },
        { url: 'img/FriendsPlus/Page36/E2/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/33.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P36-E3',
    audio: '',
    video: '',
    component: WordsBox,
    isAutofocus: true,
    typeInput: 'center',
    paddingLeft: 7,
    fontSize: 34,
    exerciseKey: 'img/FriendsPlus/Page36/E3/Key/key.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/3.jpg', input: true, answer: 'w' },
        { url: 'img/FriendsPlus/Page36/E3/4.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E3/5.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E3/6.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/7.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/10.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page36/E3/11.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/12.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E3/13.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page36/E3/14.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/17.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page36/E3/18.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E3/19.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/20.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E3/21.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page36/E3/22.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page36/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/24.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E3/25.jpg', input: true, answer: 'w' },
        { url: 'img/FriendsPlus/Page36/E3/26.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/27.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/28.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E3/29.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E3/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/31.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/32.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page36/E3/33.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E3/34.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page36/E3/35.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page36/E3/36.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E3/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/38.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/39.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E3/40.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page36/E3/41.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page36/E3/42.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/43.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/44.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/45.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page36/E3/46.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E3/47.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/48.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/49.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E3/50.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E3/51.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/52.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/53.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/54.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E3/55.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E3/56.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page36/E3/57.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page36/E3/58.jpg', input: true, answer: 'w' },
        { url: 'img/FriendsPlus/Page36/E3/59.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page36/E3/60.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page36/E3/61.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E3/62.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/63.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P36-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>In my perfect lunchbox there is chicken with rice,salad and a banana.</span></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json