import DD2 from "../../components/ExcerciseTypes/DragAndDrop/DD2";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';

const json = {
  1: { // Exercise num
    unit: 'Language Focus Practice Unit 5',
    id: 'WB6-LFP5-P70-E1',
    audio: '',
    video: '',
    component: DD2,
    exerciseKey: 'img/FriendsPlus/Page70/E1/key.png',
    titleImage: 'img/FriendsPlus/Page70/E1/title.jpg',
    // titleQuestion: [{ num: '1', title: 'Complete the table with the given words.', color: "#095BA3", star: 1 }],
    questionImage: [
      'img/FriendsPlus/Page70/E1/1.png',
      'img/FriendsPlus/Page70/E1/2.png',
    ],
    questions: [
      {
        text: "boy",
        group: 'Countable',
      },
      {
        text: "food",
        group: 'Uncountable',
      },
      {
        text: "fruit",
        group: 'Uncountable',
      },
      {
        text: "camera",
        group: 'Countable',
      },
      {
        text: "cinema",
        group: 'Countable',
      },
      {
        text: "lesson",
        group: 'Countable',
      },
      {
        text: "music",
        group: 'Uncountable',
      },
      {
        text: "pollution",
        group: 'Uncountable',
      },
      {
        text: "rain",
        group: 'Uncountable',
      },
    ]
  },
  2: { // Exercise num
    unit: 'Language Focus Practice Unit 5',
    id: 'WB6-LFP5-P70-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page70/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/3.jpg', input: true, answer: "an" },
        { url: 'img/FriendsPlus/Page70/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/6.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page70/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page70/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page70/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/10.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page70/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/12.jpg', input: true, answer: "an" },
        { url: 'img/FriendsPlus/Page70/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page70/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/15.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page70/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/17.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page70/E2/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page70/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/20.jpg', input: true, answer: "an" },
        { url: 'img/FriendsPlus/Page70/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/22.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page70/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/24.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page70/E2/25.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page70/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/27.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page70/E2/28.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page70/E2/29.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page70/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/31.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page70/E2/32.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page70/E2/33.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page70/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/35.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page70/E2/36.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page70/E2/37.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/38.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page70/E2/39.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page70/E2/40.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/41.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page70/E2/42.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page70/E2/43.jpg' },

      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Language Focus Practice Unit 5',
    id: 'WB6-LFP5-P70-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page70/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/3.jpg', input: true, answer: "How many" },
        { url: 'img/FriendsPlus/Page70/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/6.jpg', input: true, answer: "much" },
        { url: 'img/FriendsPlus/Page70/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page70/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page70/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/10.jpg', input: true, answer: "How much" },
        { url: 'img/FriendsPlus/Page70/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page70/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/13.jpg', input: true, answer: "a lot" },
        { url: 'img/FriendsPlus/Page70/E3/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page70/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/16.jpg', input: true, answer: "many" },
        { url: 'img/FriendsPlus/Page70/E3/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page70/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/19.jpg', input: true, answer: "How many" },
        { url: 'img/FriendsPlus/Page70/E3/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page70/E3/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page70/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/23.jpg', input: true, answer: "a lot" },
        { url: 'img/FriendsPlus/Page70/E3/24.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page70/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page70/E3/26.jpg', input: true, answer: "How much" },
        { url: 'img/FriendsPlus/Page70/E3/27.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Language Focus Practice Unit 5',
    id: 'WB6-LFP5-P70-E4',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page70/E4/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page70/E4/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>my grandmother / love / dance</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>My grandmother loves dancing.</u></p>" +

          "<p><span style='font-weight:600'>1</span>  Osman / like / go / to the cinema #</p>" +
          "<p><span style='font-weight:600'>2</span>  we / prefer / sit / on the sofa #</p>" +
          "<p><span style='font-weight:600'>3</span>  I / don’t mind / chat / in English #</p>" +
          "<p><span style='font-weight:600'>4</span>  you / like / play tennis / ? #</p>" +
          "<p><span style='font-weight:600'>5</span>  she / hate / swim / in the sea #</p>" +
          "<p><span style='font-weight:600'>6</span>  Denisa / not like / cook / at home #</p>" +
          "<p><span style='font-weight:600'>7</span>  my brothers / hate / make notes / in class #</p>",

        answer: [
          "Osman likes going to the cinema.",
          "We prefer sitting on the sofa.",
          "I don't mind chatting in English.",
          "Do you like playing tennis?",
          "She hates swimming in the sea.",
          "Denisa doesn't like cooking at home.",
          "My brothers hate making notes in class.",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Language Focus Practice Unit 5',
    id: 'WB6-LFP5-P70-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page70/E5/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/3.jpg', input: true, answer: "Drink" },
        { url: 'img/FriendsPlus/Page70/E5/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E5/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page70/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/7.jpg', input: true, answer: "Get up" },
        { url: 'img/FriendsPlus/Page70/E5/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page70/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/10.jpg', input: true, answer: "Don't kill" },
        { url: 'img/FriendsPlus/Page70/E5/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page70/E5/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page70/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/14.jpg', input: true, answer: "Listen" },
        { url: 'img/FriendsPlus/Page70/E5/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page70/E5/16.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/17.jpg', input: true, answer: "Don't talk" },
        { url: 'img/FriendsPlus/Page70/E5/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page70/E5/19.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/20.jpg', input: true, answer: "Repeat" },
        { url: 'img/FriendsPlus/Page70/E5/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page70/E5/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page70/E5/23.jpg' },
        { url: 'img/FriendsPlus/Page70/E5/24.jpg', input: true, answer: "Don't sing" },
        { url: 'img/FriendsPlus/Page70/E5/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Language Focus Practice Unit 5',
    id: 'WB6-LFP5-P70-E6',
    audio: '',
    video: '',
    fontSize: 22,
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page70/E6/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page70/E6/3.jpg', input: true, answer: "should listen" },
        { url: 'img/FriendsPlus/Page70/E6/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E6/5.jpg' },
        { url: 'img/FriendsPlus/Page70/E6/6.jpg', input: true, answer: "should eat" },
        { url: 'img/FriendsPlus/Page70/E6/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page70/E6/8.jpg' },
        { url: 'img/FriendsPlus/Page70/E6/9.jpg', input: true, answer: "shouldn't give" },
        { url: 'img/FriendsPlus/Page70/E6/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page70/E6/11.jpg' },
        { url: 'img/FriendsPlus/Page70/E6/12.jpg', input: true, answer: "shouldn't be" },
        { url: 'img/FriendsPlus/Page70/E6/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page70/E6/14.jpg' },
        { url: 'img/FriendsPlus/Page70/E6/15.jpg', input: true, answer: "should do" },
        { url: 'img/FriendsPlus/Page70/E6/16.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


}

export default json;
