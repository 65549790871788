import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P13-E1',
    audio: '',
    video: '',
    component: D1,
    fontSize: 24,
    exerciseKey: 'img/FriendsPlus/Page13/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Complete the table with the given words.', star: 1 }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page13/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/2.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/6.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page13/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/8.jpg', input: true, answer: "are" },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/11.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page13/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/15.jpg', input: true, answer: "isn't" },
        { url: 'img/FriendsPlus/Page13/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/17.jpg', input: true, answer: "aren't" },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/20.jpg', input: true, answer: "an" },
        { url: 'img/FriendsPlus/Page13/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/22.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page13/E1/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E1/24.jpg' },
      ],
    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P13-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page13/E2/Key/Key.jpg',
    titleQuestion: [{ num: '2', title: 'Make the sentences affimative or negative', star: 2 }],
    questionImage: [],
    questions: [
      {
        // eslint-disable-next-line no-useless-concat
        title: '<p>There are some shops in my street. ✘</p>' +
          '<p style="font-style: italic;font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray"><u><b>&nbsp;&nbsp;There aren’t any shops in my street.&nbsp;&nbsp;</b></u></p>'
          + "<p><span style='font-weight:600'>1</span> There’s a theatre near here. ✘ </p> <p>#</p>"
          + "<p><span style='font-weight:600'>2</span> There isn’t a nice sports centre in my area. ✔</p> <p>#</p>"
          + "<p><span style='font-weight:600'>3</span> There aren’t any pretty trees in the park. ✔</p> <p>#</p>"
          + "<p><span style='font-weight:600'>4</span> There are some monuments in the square. ✘ </p> <p>#</p>"
          + "<p><span style='font-weight:600'>5</span> There’s a five-star hotel in the town. ✘ </p> <p>#</p>",
        answer: [
          "There isn't a theatre near here.",
          "There's a nice sports centre in my area.",
          "There are some pretty trees in the park.",
          "There aren't any monuments in the square.",
          "There isn't a five-star hotel in the town."],
        type: 'longAnwser'
      },
    ]
  },
  3: {
    unit: 'Unit 1',
    id: 'WB6-U1-P13-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page13/E3/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 8,
    titleQuestion: [{ num: '3', title: "Choose the correct words", star: 1, color: '#53C4D7' }],
    questionImage: [ // Row

      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/1.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/2.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/3.jpg', input: 1 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/4.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/5.jpg', input: 1, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/6.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/7.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/8.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/9.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/10.jpg', input: 2, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/11.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/12.jpg', input: 2 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/13.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/14.jpg', input: 3 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/15.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/16.jpg', input: 3, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/17.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/18.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/19.jpg', input: 4 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/20.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/21.jpg', input: 4, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/22.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/23.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/24.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/25.jpg', input: 5 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/26.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/27.jpg', input: 5, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/28.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/29.jpg', input: 6, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/30.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/31.jpg', input: 6 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/32.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/33.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/34.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/35.jpg', input: 7 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/36.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/37.jpg', input: 8, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/38.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/39.jpg', input: 8 },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/40.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/41.jpg' },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/42.jpg', input: 7, isCorrect: true },
      //   { url: 'img/FriendsPlus/Page13/E3/temp/43.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page13/E3/temp/44.jpg' },
      // ]
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page13/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page13/E3/4.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/5.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page13/E3/6.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page13/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/8.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page13/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/11.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page13/E3/12.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/13.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page13/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/15.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page13/E3/16.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page13/E3/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page13/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/20.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page13/E3/21.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/23.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/24.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page13/E3/25.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page13/E3/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page13/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/28.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page13/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/30.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/31.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page13/E3/32.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page13/E3/33.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/34.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/35.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P13-E4',
    audio: '',
    video: '',
    component: T5,
    exerciseKey: '',
    titleImage: 'img/FriendsPlus/Page13/E4/title.jpg',
    imgSize: '70%',
    inputSize: 600,
    // titleQuestion: [{ num: '4', title: 'A friend from a different town visits you. He / she has got a lot of questions about your town! Write the questions and the answers. Use the given words to help you.', star: 3 }],
    questionImage: [],
    questions: [
      {
        // eslint-disable-next-line no-useless-concat
        title:
          "<p><span style='font-weight:600'>Friend </span><u style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'>Are there any good shops in this town?</u> </p>" +
          "<p><span style='font-weight:600'>You </span><u style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'>Yes, there are. There are some interesting shops in the new shopping centre.</u> </p>" +
          "<p><span style='font-weight:600'>Friend</span> # </p>" +
          "<p><span style='font-weight:600'>You</span> # </p>" +
          "<p><span style='font-weight:600'>Friend</span> # </p>" +
          "<p><span style='font-weight:600'>You</span> # </p>" +
          "<p><span style='font-weight:600'>Friend</span> # </p>" +
          "<p><span style='font-weight:600'>You</span> # </p>",
        answer: [],
        type: 'longAnwserInLine'
      },
    ]
  },
  5: {
    unit: 'Unit 1',
    id: 'WB6-U1-P13-E5',
    audio: '',
    video: '',
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page13/E5/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    titleQuestion: [{ num: '5', title: 'Are the sentences correct (✔) or incorrect (✘)?', star: 2 }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page13/E5/Images/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E5/Images/2.jpg' },
        { url: 'img/FriendsPlus/Page13/E5/Images/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page13/E5/Images/4.jpg', input: 1, isCorrect: true },

      ],
      [
        { url: 'img/FriendsPlus/Page13/E5/Images/5.jpg' },
        { url: 'img/FriendsPlus/Page13/E5/Images/6.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E5/Images/7.jpg', input: 2 },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E5/Images/8.jpg' },
        { url: 'img/FriendsPlus/Page13/E5/Images/9.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page13/E5/Images/10.jpg', input: 3, isCorrect: true },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E5/Images/11.jpg' },
        { url: 'img/FriendsPlus/Page13/E5/Images/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page13/E5/Images/13.jpg', input: 4, isCorrect: true },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E5/Images/14.jpg' },
        { url: 'img/FriendsPlus/Page13/E5/Images/15.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E5/Images/16.jpg', input: 5 },
      ],
      [
        { url: 'img/FriendsPlus/Page13/E5/Images/17.jpg' },
        { url: 'img/FriendsPlus/Page13/E5/Images/15.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E5/Images/19.jpg', input: 6 },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


}

export default json;