
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox2';

const json = {
    1: { // Exercise num
        unit: 'Starter Unit',
        id: 'WB6-S-P8-E1',
        audio: '',
        video: '',
        component: WordsBox,
        isAutofocus: true,
        fontSize: 22,
        typeInput: 'center',
        exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
        titleImage: '',
        // titleQuestion: [{ num: '1', title: 'Look at the pictures. Complete the words.', color: "#66A1B5", star: 1 }],
        questionImage: [ // Row
            // [
            //     // Column1
            //     { url: 'img/FriendsPlus/Page8/E1/1.jpg' },
            // ],
            // [
            //     // Column2
            //     { url: 'img/FriendsPlus/Page8/E1/2.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/3.jpg', input: true, answer: "l" },
            //     { url: 'img/FriendsPlus/Page8/E1/4.jpg', input: true, answer: "d" },
            //     { url: 'img/FriendsPlus/Page8/E1/5.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/6.jpg', input: true, answer: "a" },
            //     { url: 'img/FriendsPlus/Page8/E1/7.jpg', input: true, answer: "l" },
            //     { url: 'img/FriendsPlus/Page8/E1/8.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/9.jpg', input: true, answer: "p" },
            //     { url: 'img/FriendsPlus/Page8/E1/10.jpg', input: true, answer: "e" },
            //     { url: 'img/FriendsPlus/Page8/E1/11.jpg', input: true, answer: "s" },
            //     { url: 'img/FriendsPlus/Page8/E1/12.jpg', input: true, answer: "v" },
            //     { url: 'img/FriendsPlus/Page8/E1/13.jpg' },
            // ],
            // [
            //     // Column3
            //     { url: 'img/FriendsPlus/Page8/E1/14.jpg' },
            // ],
            // [
            //     // Column4
            //     { url: 'img/FriendsPlus/Page8/E1/15.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/16.jpg', input: true, answer: "n" },
            //     { url: 'img/FriendsPlus/Page8/E1/17.jpg', input: true, answer: "e" },
            //     { url: 'img/FriendsPlus/Page8/E1/18.jpg', input: true, answer: "e" },
            //     { url: 'img/FriendsPlus/Page8/E1/19.jpg', input: true, answer: "t" },
            //     { url: 'img/FriendsPlus/Page8/E1/20.jpg', input: true, answer: "n" },
            //     { url: 'img/FriendsPlus/Page8/E1/21.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/22.jpg', input: true, answer: "o" },
            //     { url: 'img/FriendsPlus/Page8/E1/23.jpg', input: true, answer: "u" },
            //     { url: 'img/FriendsPlus/Page8/E1/24.jpg', input: true, answer: "a" },
            //     { url: 'img/FriendsPlus/Page8/E1/25.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/26.jpg', input: true, answer: "o" },
            //     { url: 'img/FriendsPlus/Page8/E1/27.jpg', input: true, answer: "i" },
            //     { url: 'img/FriendsPlus/Page8/E1/28.jpg', input: true, answer: "g" },
            //     { url: 'img/FriendsPlus/Page8/E1/29.jpg' },
            //     { url: 'img/FriendsPlus/Page8/E1/30.jpg', input: true, answer: "o" },
            //     { url: 'img/FriendsPlus/Page8/E1/31.jpg', input: true, answer: "r" },
            //     { url: 'img/FriendsPlus/Page8/E1/32.jpg', input: true, answer: "b" },
            //     { url: 'img/FriendsPlus/Page8/E1/33.jpg', input: true, answer: "e" },
            //     { url: 'img/FriendsPlus/Page8/E1/34.jpg' },
            // ],
            // [
            //     // Column5
            //     { url: 'img/FriendsPlus/Page8/E1/35.jpg' },
            // ],
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/3.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page8/E1/4.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/5.jpg', input: true, answer: "d" },
                { url: 'img/FriendsPlus/Page8/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/7.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page8/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/9.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page8/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/11.jpg', input: true, answer: "p" },
                { url: 'img/FriendsPlus/Page8/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/13.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page8/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/15.jpg', input: true, answer: "s" },
                { url: 'img/FriendsPlus/Page8/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/17.jpg', input: true, answer: "v" },
                { url: 'img/FriendsPlus/Page8/E1/18.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E1/19.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/21.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page8/E1/22.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/23.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page8/E1/24.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/25.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page8/E1/26.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/27.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page8/E1/28.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/29.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page8/E1/30.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/31.jpg', input: true, answer: "o" },
                { url: 'img/FriendsPlus/Page8/E1/32.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/33.jpg', input: true, answer: "u" },
                { url: 'img/FriendsPlus/Page8/E1/34.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/35.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page8/E1/36.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/37.jpg', input: true, answer: "o" },
                { url: 'img/FriendsPlus/Page8/E1/38.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/39.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page8/E1/40.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/41.jpg', input: true, answer: "g" },
                { url: 'img/FriendsPlus/Page8/E1/42.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/43.jpg', input: true, answer: "o" },
                { url: 'img/FriendsPlus/Page8/E1/44.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/45.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page8/E1/46.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/47.jpg', input: true, answer: "b" },
                { url: 'img/FriendsPlus/Page8/E1/48.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/49.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page8/E1/50.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page8/E1/51.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Starter Unit',
        id: 'WB6-S-P8-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 28,
        height: 30,
        exerciseKey: 'img/FriendsPlus/Page8/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/3.jpg', input: true, answer: "nice" },
                { url: 'img/FriendsPlus/Page8/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/7.jpg', input: true, answer: "boring" },
                { url: 'img/FriendsPlus/Page8/E2/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page8/E2/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page8/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/11.jpg', input: true, answer: "cheap" },
                { url: 'img/FriendsPlus/Page8/E2/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page8/E2/13.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page8/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/15.jpg', input: true, answer: "slow" },
                { url: 'img/FriendsPlus/Page8/E2/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page8/E2/17.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page8/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/19.jpg', input: true, answer: "unpopular" },
                { url: 'img/FriendsPlus/Page8/E2/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page8/E2/21.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page8/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/23.jpg', input: true, answer: "bad" },
                { url: 'img/FriendsPlus/Page8/E2/24.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page8/E2/25.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page8/E2/26.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/27.jpg', input: true, answer: "old" },
                { url: 'img/FriendsPlus/Page8/E2/28.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page8/E2/29.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page8/E2/30.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/31.jpg', input: true, answer: "small" },
                { url: 'img/FriendsPlus/Page8/E2/32.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit: 'Starter Unit',
        id: 'WB6-S-P8-E3',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        height: 34,
        exerciseKey: 'img/FriendsPlus/Page8/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/3.jpg', input: true, answer: "new" },
                { url: 'img/FriendsPlus/Page8/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/6.jpg', input: true, answer: "fast" },
                { url: 'img/FriendsPlus/Page8/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/9.jpg', input: true, answer: "small" },
                { url: 'img/FriendsPlus/Page8/E3/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page8/E3/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page8/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/13.jpg', input: true, answer: "big" },
                { url: 'img/FriendsPlus/Page8/E3/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page8/E3/15.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/16.jpg', input: true, answer: "good" },
                { url: 'img/FriendsPlus/Page8/E3/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page8/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/19.jpg', input: true, answer: "popular" },
                { url: 'img/FriendsPlus/Page8/E3/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page8/E3/21.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Starter Unit',
        id: 'WB6-S-P8-E4',
        audio: '',
        video: '',
        component: D1,
        //exerciseKey: 'img/FriendsPlus/Page19/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E4/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/3.jpg', input: true, answer: "" },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E4/4.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/5.jpg', input: true, answer: "" },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E4/6.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/7.jpg', input: true, answer: "" },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page8/E4/8.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/9.jpg', input: true, answer: "" },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page8/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/11.jpg', input: true, answer: "" },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page8/E4/12.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/13.jpg', input: true, answer: "" },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page8/E4/14.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/15.jpg', input: true, answer: "" },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page8/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/17.jpg', input: true, answer: "" },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },



}

export default json;