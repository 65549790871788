import React from 'react';
import { Form } from 'antd';
// import { Row, Col } from 'antd';
import { Container, Row, Button, Input } from 'reactstrap';

import FooterModal from '../../../FooterModal';
import { hexToRgbA } from '../../../../constants';
import TitleQuestion from '../../../TitleQuestion';
import moment from 'moment';
import 'moment/locale/vi';

moment.locale('vi');
const FormItem = Form.Item;

const WordsBox = (props) => {
  const [state, setState] = React.useState({
    data: [],
    values: {},
    answers: [],
    isDoing: true,
    maxInput: null,
    audioUrl: null,
    booleanArray: [],
    isDisabledSubmit: true,
    isDisabledRetry: true,
    inputArray: []
  });

  const inputCount = React.useRef(0);
  const submitButtonRef = React.useRef();
  const [formCheckAnswer] = Form.useForm();
  const itemHeight = React.useMemo(() => props.questionImage[0][0].itemHeight || 71, [props.questionImage]);

  React.useEffect(() => {
    const data = JSON.parse(JSON.stringify(props.questionImage));
    let maxInput = 0;
    let answers = []
    data.forEach((item) => {
      item.forEach((img) => {
        if (img.input) {
          maxInput++
          answers.push(img.answer);
        }
      })
    })

    const audioUrl = props.audio;
    data.titleQuestion = props.titleQuestion;

    setState((prevState) => ({ ...prevState, data, maxInput, answers, audioUrl }));
  }, [props.audio, props.questionImage, props.titleQuestion]);

  const onChangeText = React.useCallback((inputIndex) => (e) => {
    const text = e.target.value;
    let emptyInputNum = 0;
    state.inputArray[inputIndex] = text
    state.inputArray.forEach((item, index) => {
      if (item) {
        emptyInputNum++
      }
    })
    // Auto focus into next Input 
    if (props.isAutofocus) {
      const { maxLength, value, id } = e.target;
      if (maxLength === value.length) {
        document.getElementById(+id + 1)?.focus();
      }
    }
    ////
    if (emptyInputNum === state.maxInput) {
      setState((prevState) => ({ ...prevState, isDisabledSubmit: false }))
    }
    else {
      setState((prevState) => ({ ...prevState, isDisabledRetry: false }))
    }

  }, [props.isAutofocus, state.inputArray, state.maxInput])

  const renderRow = React.useCallback((row, rowIndex) => {
    return row.map((item, index) => {
      let className = "";

      const isInput = item.input;

      let color = 'black';
      let backgroundColor = 'transparent';
      let inputCurrent = 0;
      let isCorrect = false;
      if (isInput) {
        inputCurrent = inputCount.current;
        inputCount.current++;
        if (inputCurrent === state.maxInput - 1) {
          inputCount.current = 0;
        }
        if (!state.isDoing) {
          color = 'white';
          isCorrect = state.booleanArray[inputCurrent];
          backgroundColor = isCorrect ? '#2DCE89' : '#F13536';
          backgroundColor = hexToRgbA(backgroundColor, 0.7)
        }
      }

      return (
        <div key={index} style={{ position: 'relative', display: 'grid' }}>
          <img
            className={className}
            style={{ position: isInput ? 'relative' : '' }}
            src={item.url} alt='...'
          />
          {isInput && (
            <FormItem
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: 0,
                marginBottom: 0,
                backgroundColor,
                position: 'absolute',
                display: 'inline-block',
              }}
              name={inputCurrent}
            >
              <Input
                allowClear
                onChange={onChangeText(inputCurrent)}
                disabled={!state.isDoing}
                autocapitalize="words"
                autoComplete={false}
                autoCorrect={false}
                maxLength={1}
                style={{
                  color,
                  width: '100%',
                  height: itemHeight,
                  padding: 0,
                  fontSize: 35,
                  paddingTop: 0,
                  paddingLeft: 0,
                  borderWidth: 0,
                  borderRadius: 0,
                  fontWeight: '500',
                  textAlign: 'center',
                  // marginBottom: 16,
                  // borderColor: color,
                  backgroundColor: 'transparent',
                }}
              />

            </FormItem>

          )}
          {/* {!state.isDoing && isInput && (
            <i
              className={classNames({
                "fas fa-check": state.booleanArray[inputCurrent],
                "fas fa-times": !state.booleanArray[inputCurrent]
              })}
              style={{
                color,
                top: -1,
                right: 0,
                fontSize: 20,
                position: 'absolute',
              }}
            />
          )} */}

        </div>
      );
    });
  }, [itemHeight, onChangeText, state.booleanArray, state.isDoing, state.maxInput]);

  const renderContent = React.useMemo(() => {
    // const maxInput = state.data.
    return state.data.map((rowItem, rowIndex) => {
      return (
        <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {renderRow(rowItem, rowIndex)}
        </div>
      )
    })
  }, [renderRow, state.data]);

  const onSubmit = React.useCallback(() => {
    submitButtonRef.current?.click();
    setState((prevState) => ({
      ...prevState,
      isDoing: false,
      isDisabledRetry: false,
      isDisabledSubmit: true,
    }))
  }, []);

  const onRetry = React.useCallback(() => {
    formCheckAnswer.resetFields();
    setState((prevState) => ({
      ...prevState,
      values: {},
      inputArray: [],
      isDoing: true,
      isDisabledRetry: true
    }))
  }, [formCheckAnswer]);

  const onFinish = React.useCallback((values) => {
    let booleanArray = []
    let studentAnswer = [];
    for (let i = 0; i < state.maxInput; i++) {
      let isCorrect = false;
      if (values[i]) {
        isCorrect = values[i].trim().toLowerCase() === state.answers[i].trim().toLowerCase();
        studentAnswer.push(values[i])
      }
      else {
        studentAnswer.push(null)
      }
      booleanArray.push(isCorrect);
    }
    let correctAnswer = 0;
    booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / booleanArray.length * 100)
    // const resultString = `${correctAnswer}/${booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${booleanArray.length}`

    const params = {
      score: percent,
      unit: props.unit,
      results: result_String,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    props.postAnswerToApi(params)
    setState((prevState) => ({
      ...prevState,
      values,
      booleanArray,
      isDoing: false,
    }))
  }, [props, state.answers, state.maxInput]);

  const isKeyVisible = state.values.hasOwnProperty('0') ? Object.keys(state.values).some((key) => !!state.values[key]) : false;

  if (!state.data) return null;

  console.log(state);
  return (
    <Container className='fluid'>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TitleQuestion titleQuestion={state.data?.titleQuestion} />
      </Row>
      <Row style={{ minWidth: 985, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Form form={formCheckAnswer} autoComplete="off" onFinish={onFinish}>
          {renderContent}
          <Button innerRef={submitButtonRef} style={{ display: 'none' }} htmlType="submit" >text</Button>
        </Form>
      </Row>

      <FooterModal
        audioUrl={state.audioUrl}
        exerciseKey={props.exerciseKey}
        result={!state.isDoing ? state.booleanArray : null}
        isDisabledRetry={state.isDisabledRetry}
        isDisabledSubmit={state.isDisabledSubmit}
        onRetry={onRetry}
        onSubmit={onSubmit}
      />

    </Container>
  )
};

export default WordsBox;
