import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: {
    unit: 'Unit 6',
    id: 'WB6-U6-P46-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page46/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 1,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page46/E1/03.jpg' },
      ],
      [

        { url: 'img/FriendsPlus/Page46/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/06.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page46/E1/07.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/08.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/09.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/12.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page46/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/14.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB6-U6-P46-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page46/E2/Key/Key.png',
    component: D1,
    fontSize: 25,
    titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/6.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page46/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/10.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page46/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/14.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page46/E2/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/18.jpg', input: true, answer: "E" },
        { url: 'img/FriendsPlus/Page46/E2/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/22.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page46/E2/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E2/24.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 6',
    id: 'WB6-U6-P46-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page46/E3/Key/Key.png',
    component: D1,
    fontSize: 25,
    titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/6.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page46/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/10.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page46/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/14.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page46/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/18.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page46/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/22.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page46/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/26.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page46/E3/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/28.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 6',
    id: 'WB6-U6-P46-E4',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page46/E4/Key/Key.png',
    // imgSize: '65%',
    // titleImage: 'img/FriendsPlus/Page46/E4/title.jpg',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: "#de2f3a", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Why is wheelchair basketball good to watch? </p>" +
          "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> Because it’s fast and exciting. </u></p>" +
          "<p><span style='font-weight:600'>1 </span>Where was the first wheelchair basketball match? </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>2 </span>When were the first Paralympic Games?</p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>3 </span>How many sports were there in the first Paralympic Games?</p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>4 </span>Who were the winners of the first Paralympic basketball gold medal? </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>5 </span>How long are wheelchair basketball matches?</p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>6 </span>What other Paralympic sports do you know about?</p>" +
          "<p>#</p>",
        answer: [
          "It was in the USA.",
          "The first Paralympic Games were in 1960.",
          "There were eight sports.",
          "The winners were the British team.",
          "They are forty minutes long.",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 6',
    id: 'WB6-U6-P46-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page46/E5/Key/Key.png',
    imgSize: '80%',
    inputSize: 160,
    titleImage: 'img/FriendsPlus/Page46/E5/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Look at that boy in the swimming pool. He’s a fantastic &#160;&#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> swimmer </u>&#160;&#160; . </p>" +
          "<p><span style='font-weight:600'>1 </span>Two thousand # watched the race yesterday.</p>" +
          "<p><span style='font-weight:600'>2 </span>She was a gold # at the 2012 Olympics. </p>" +
          "<p><span style='font-weight:600'>3 </span>I was the best person in the competition. I was the # !</p>" +
          "<p><span style='font-weight:600'>4 </span>There were 150  # in this marathon last year.</p>",
        answer: [
          "spectators",
          "medallist",
          "winner",
          "competitors",
        ],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;