import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Bg1 from "../../assets/img/Bg1.jpg";
import NotFoundImg from "./404.png";

const NotFound = () => {
  const history = useHistory();
  return (
    <div
      className={`ant-result ant-result-info`}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        height: "calc(100vh - 30px)",
        justifyContent: "center",
        // paddingBottom: "20%",
        backgroundImage: `url(${Bg1})`,
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      {/* <img
        src="img/logo.png"
        alt="logo"
        style={{ width: 400, marginLeft: 50 }}
      /> */}
      <div
        // className="ant-result-subtitle"
        style={{
          color: "#2dce89",
          fontSize: 30,
          fontWeight: "bold",
        }}
      >
        Look like you're lost in space
      </div>
      <div style={{ position: "relative" }}>
        <h1
          className="ant-result-title"
          style={{
            fontSize: 300,
            color: "#2dce89",
            marginTop: -130,
            marginBottom: -70,
          }}
        >
          404
        </h1>
        <img
          src={NotFoundImg}
          alt="404"
          style={{
            position: "absolute",
            top: "calc(50% - 80px)",
            left: "calc(50% - 75px)",
            width: 150,
          }}
        />
      </div>

      {/* <p
        className="ant-result-subtitle"
        style={{ color: "white", textShadow: "0 0 10px black" }}
      >
        Xin lỗi, không thể tìm thấy trang bạn yêu cầu.
      </p> */}
      {/* <p
        className="ant-result-subtitle"
        style={{
          color: "rgb(45, 206, 137, 0.9)",
          background: "rgba(255,255,255,0.4)",
          padding: "10px 20px",
          borderRadius: 20,
        }}
      >
        Xin lỗi, không thể tìm thấy trang bạn yêu cầu.
      </p> */}
      <Button
        type="primary"
        className="btn btn-success"
        onClick={() => history.push("/")}
        style={{ padding: "8px 15px", fontSize: 18, height: "auto" }}
      >
        Back To Home
      </Button>
    </div>
    //
  );
};

export default NotFound;
