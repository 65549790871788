
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

    1: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P55-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 20,
        exerciseKey: 'img/FriendsPlus/Page55/E1/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '1', title: 'Complete the table with the given words.', color: "#1dbed7", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page55/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/3.jpg', input: true, answer: "'s" },
                { url: 'img/FriendsPlus/Page55/E1/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/7.jpg', input: true, answer: "'re" },
                { url: 'img/FriendsPlus/Page55/E1/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/11.jpg', input: true, answer: "'m not" },
                { url: 'img/FriendsPlus/Page55/E1/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/14.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page55/E1/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/18.jpg', input: true, answer: "aren't" },
                { url: 'img/FriendsPlus/Page55/E1/19.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/21.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/22.jpg', input: true, answer: "Am" },
                { url: 'img/FriendsPlus/Page55/E1/23.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/24.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/25.jpg', input: true, answer: "Is" },
                { url: 'img/FriendsPlus/Page55/E1/26.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/27.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/28.jpg', input: true, answer: "Are" },
                { url: 'img/FriendsPlus/Page55/E1/29.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/30.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/31.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/32.jpg', input: true, answer: "am" },
                { url: 'img/FriendsPlus/Page55/E1/33.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/34.jpg', input: true, answer: "'m not" },
                { url: 'img/FriendsPlus/Page55/E1/35.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/36.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/37.jpg', input: true, answer: "is" },
                { url: 'img/FriendsPlus/Page55/E1/38.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/39.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page55/E1/40.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/41.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/42.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/43.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page55/E1/44.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/45.jpg', input: true, answer: "aren't" },
                { url: 'img/FriendsPlus/Page55/E1/46.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E1/47.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P55-E2',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page55/E2/Key/Key.png',
        titleImage: '',
        titleQuestion: [{ num: '2', title: 'Order the words to make sentences.', color: "#1dbed7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>holiday / going / I'm / to / on / go</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I'm going to go on holiday.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. mum / my / going / take / suitcase / is / a / to # </p>" +
                    "<p><span style='font-weight:600'>2</span>. going / I'm / waterproof / to / a / small / take # </p>" +
                    "<p><span style='font-weight:600'>3</span>. to / going / brother / study / isn't / my # </p>" +
                    "<p><span style='font-weight:600'>4</span>. homework / any / not / I'm / going / do / to # </p>" +
                    "<p><span style='font-weight:600'>5</span>. aren't / watch / to / going / TV / we # </p>",
                answer: [
                    "My mum is going to take a suitcase.",
                    "I'm going to take a small waterproof.",
                    "My brother isn't going to study.",
                    "I'm not going to do any homework.",
                    "We aren't going to watch TV.",
                ],
                type: 'longAnwser'
            },
        ]
    },

    3: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P55-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page55/E3/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '3', title: 'It is the first day of a school camp. Write the questions the students ask.', color: "#1dbed7", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page55/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/0.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page55/E3/3.jpg', input: true, answer: "am I going to sleep" },
                { url: 'img/FriendsPlus/Page55/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/0.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page55/E3/6.jpg', input: true, answer: "Is Jo going to be" },
                { url: 'img/FriendsPlus/Page55/E3/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/0.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page55/E3/9.jpg', input: true, answer: "are we going to do" },
                { url: 'img/FriendsPlus/Page55/E3/10.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/0.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/11.jpg' },
                { url: 'img/FriendsPlus/Page55/E3/12.jpg', input: true, answer: "Are you going to help" },
                { url: 'img/FriendsPlus/Page55/E3/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E3/14.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P55-E4',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page55/E4/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1dbed7", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page55/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page55/E4/3.jpg', input: true, answer: "won't" },
                { url: 'img/FriendsPlus/Page55/E4/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/6.jpg' },
                { url: 'img/FriendsPlus/Page55/E4/7.jpg', input: true, answer: "Will" },
                { url: 'img/FriendsPlus/Page55/E4/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page55/E4/11.jpg', input: true, answer: "take" },
                { url: 'img/FriendsPlus/Page55/E4/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/14.jpg' },
                { url: 'img/FriendsPlus/Page55/E4/15.jpg', input: true, answer: "will" },
                { url: 'img/FriendsPlus/Page55/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page55/E4/17.jpg', input: true, answer: "won't" },
                { url: 'img/FriendsPlus/Page55/E4/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E4/19.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    5: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P55-E5',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page55/E5/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '5', title: 'Complete the text message with ’ll or won’t and the verb.', color: "#1dbed7", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page55/E5/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/2.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/3.jpg', input: true, answer: "'ll be" },
                { url: 'img/FriendsPlus/Page55/E5/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/5.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/6.jpg', input: true, answer: "won't rain" },
                { url: 'img/FriendsPlus/Page55/E5/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/8.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/9.jpg', input: true, answer: "won't need" },
                { url: 'img/FriendsPlus/Page55/E5/10.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/11.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/12.jpg', input: true, answer: "'ll need" },
                { url: 'img/FriendsPlus/Page55/E5/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/14.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/15.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/16.jpg', input: true, answer: "'ll give" },
                { url: 'img/FriendsPlus/Page55/E5/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/18.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/19.jpg', input: true, answer: "'ll enjoy" },
                { url: 'img/FriendsPlus/Page55/E5/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/21.jpg' },
                { url: 'img/FriendsPlus/Page55/E5/22.jpg', input: true, answer: "won't forget" },
                { url: 'img/FriendsPlus/Page55/E5/23.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page55/E5/24.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    6: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P55-E6',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        // titleImage: 'img/FriendsPlus/Page55/E6/1.jpg',
        titleQuestion: [{ num: '6', title: 'A friend from the UK is going to visit your country for the first time this summer. Write sentences with will and won’t in your notebook.', color: '#1dbed7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:400;font-size: 40px;font-family:OneStrokeScriptStd-Bold;color:gray'><u>You’ll like the food here – it’s really great!</u></p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
}

export default json;