
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {

  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P7-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page7/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E1/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page7/E1/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page7/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/14.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page7/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/18.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E1/19.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page7/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/23.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E1/24.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page7/E1/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P7-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/Key.png',
    component: T6,
    imgSize: '80%',
    inputSize: 380,
    titleImage: 'img/FriendsPlus/Page7/E2/title.jpg',
    leftImage: 'img/FriendsPlus/Page7/E2/1.jpg',
    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'> Luke’s pen </span></p>" +
          "<p><span style='font-weight:600'>1</span>#</p>" +
          "<p><span style='font-weight:600'>2</span>#</p>" +
          "<p><span style='font-weight:600'>3</span>#</p>" +
          "<p><span style='font-weight:600'>4</span>#</p>" +
          "<p><span style='font-weight:600'>5</span>#</p>",
        answer: [
          "the teachers' table / the teacher's table",
          "Betty's mobile phone",
          "our friends' house / our friend's house",
          "Sam's teacher ",
          "the student's bag",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Starter Unit',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page7/E3/03.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page7/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page7/E3/07.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E3/08.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page7/E3/09.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page7/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page7/E3/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E3/17.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page7/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/21.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page7/E3/22.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/24.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P7-E4',
    audio: '',
    video: '',
    component: D1,
    fontSize: 28,
    exerciseKey: 'img/FriendsPlus/Page7/E4/Key/7_4.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/3.jpg', input: true, answer: " am " },
        { url: 'img/FriendsPlus/Page7/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/5.jpg', input: true, answer: "  'm not  " },
        { url: 'img/FriendsPlus/Page7/E4/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/9.jpg', input: true, answer: "  Is  " },
        { url: 'img/FriendsPlus/Page7/E4/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/12.jpg', input: true, answer: "  is  " },
        { url: 'img/FriendsPlus/Page7/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/14.jpg', input: true, answer: "  isn't  " },
        { url: 'img/FriendsPlus/Page7/E4/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/18.jpg', input: true, answer: "  Are  " },
        { url: 'img/FriendsPlus/Page7/E4/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/22.jpg', input: true, answer: " are " },
        { url: 'img/FriendsPlus/Page7/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/24.jpg', input: true, answer: "  aren't " },
        { url: 'img/FriendsPlus/Page7/E4/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  5: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P7-E5',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page7/E5/Key/Key.png',
    component: T2,
    // titleImage: 'img/FriendsPlus/Page7/E5/title.jpg',
    titleQuestion: [{ num: '5', title: ' Imagine there is a new student in your class. Write questions with be.', color: '#28b9d8', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'>Are you eleven?</span></p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>",
        answer: [
        ],
        type: 'longAnwser'
      },
    ]
  },

}
export default json