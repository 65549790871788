import MCTI1 from '../../components/ExcerciseTypes/MultipleChoice&TypeIn/MC&TI1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {
  1: { // Exercise num
    unit : 'Unit 3',
    id : 'WB6-U3-P25-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/3.jpg', input: true, answer: "the strangest" },
        { url: 'img/FriendsPlus/Page25/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page25/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/6.jpg', input: true, answer: "the noisiest" },
        { url: 'img/FriendsPlus/Page25/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page25/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/9.jpg', input: true, answer: "the hottest" },
        { url: 'img/FriendsPlus/Page25/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page25/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page25/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/13.jpg', input: true, answer: "the most beautiful/the most common/the most exciting/the most famous" },
        { url: 'img/FriendsPlus/Page25/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/15.jpg', input: true, answer: "the most beautiful/the most common/the most exciting/the most famous" },
        { url: 'img/FriendsPlus/Page25/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page25/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/18.jpg', input: true, answer: "the most beautiful/the most common/the most exciting/the most famous" },
        { url: 'img/FriendsPlus/Page25/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/20.jpg', input: true, answer: "the most beautiful/the most common/the most exciting/the most famous" },
        { url: 'img/FriendsPlus/Page25/E1/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page25/E1/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page25/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/24.jpg', input: true, answer: "the worst/the furthest/the best" },
        { url: 'img/FriendsPlus/Page25/E1/25.jpg', input: true, answer: "the worst/the furthest/the best" },
        { url: 'img/FriendsPlus/Page25/E1/26.jpg', input: true, answer: "the worst/the furthest/the best" },
        { url: 'img/FriendsPlus/Page25/E1/27.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page25/E1/28.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit : 'Unit 3',
    id : 'WB6-U3-P25-E2',
    audio: '',
    video: '',
    component: MCTI1,
    exerciseKey: 'img/FriendsPlus/page25/E2/Key/answerKey.png',
    titleImageQuestion: 'img/FriendsPlus/Page25/E2/imgQuestion.jpg',
    marginLeft: 15,
    width: '75%',
    titleImage: 'img/FriendsPlus/page25/E2/title.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: `The # (big) animal in the world is the ....... .`,
        title1: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "African elephant" },
          { image: "", isCorrect: true, right: "", text: "blue whale" },
          { image: "", isCorrect: false, right: "", text: "brown bear" },
        ],
        titleAnswer: `biggest`,
        no: 1,
        question: '',
        type: '',
      },
      {
        title: `The # (old) zoo in the world is in Vienna,`,
        title1: ' Austria. It is more than ....... years old.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "100" },
          { image: "", isCorrect: false, right: "", text: "150" },
          { image: "", isCorrect: true, right: "", text: "250" },
        ],
        titleAnswer: 'oldest',
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'One of the # (common) wild animals in the UK is the ....... .',
        title1: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "rabbit" },
          { image: "", isCorrect: false, right: "", text: "bear" },
          { image: "", isCorrect: false, right: "", text: "snake" },
        ],
        titleAnswer: 'most common',
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'The # (long) snakes in the world live in .......',
        title1: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Africa" },
          { image: "", isCorrect: false, right: "", text: "India" },
          { image: "", isCorrect: true, right: "", text: "Southeast Asia" },
        ],
        titleAnswer: 'longest',
        no: 4,
        question: '',
        type: '',
      },
      {
        title: 'The # (busy) zoo in Europe is in ....... .',
        title1: 'There are about three million visitors every year.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Prague" },
          { image: "", isCorrect: true, right: "", text: "Berlin" },
          { image: "", isCorrect: false, right: "", text: "Paris" },
        ],
        titleAnswer: `busiest`,
        no: 5,
        question: '',
        type: '',
      },
    ]
  },
  3: { // Exercise num
    unit : 'Unit 3',
    id : 'WB6-U3-P25-E3',
    audio: '',
    video: '',
    imgSize: 850,
    exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    component: T6,
    titleImage: 'img/FriendsPlus/Page25/E3/title.png',
    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Look at this colourful butterfly! It’s #butterfly in the park. </p>" +
          "<p><span style='font-weight:600'>2</span> Olga is a good student. She’s # student in the school.</p>" +
          "<p><span style='font-weight:600'>3</span> That is an expensive phone. It’s # phone in the shop. </p>" +
          "<p><span style='font-weight:600'>4</span> It’s a very hot day today. It’s # day of the year. </p>" +
          "<p><span style='font-weight:600'>5</span> This monument is really old. It’s # monument in our city.</p>" +
          "<p><span style='font-weight:600'>6</span> My brother Ivan is a friendly boy. He’s # boy in our village.</p>" +
          "<p><span style='font-weight:600'>7</span> This lesson is very interesting. It’s # lesson of the morning.</p>" +
          "<p><span style='font-weight:600'>8</span> That café is really bad. It’s # café in the area!</p>",
        answer: [
          "the most colourful",
          "the best",
          "the most expensive",
          "the hottest",
          "the oldest",
          "the friendliest",
          "the most interesting ",
          "the worst",
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit : 'Unit 3',
    id : 'WB6-U3-P25-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Write superlative sentences to give information about your town to a new student in your class. Use the given words to help you.', color: '#41CADC', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>The best shopping centre is in the next town. The quietest beach is ...</u></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },



}

export default json;