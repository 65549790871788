import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'WB6-U8-P58-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page58/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 3,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page58/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page58/E1/07.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page58/E1/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page58/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page58/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/14.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page58/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page58/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E1/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P58-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page58/E2/Key/Key.png',
    // titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Read the text again. Match questions 1–5 with paragraphs A–F.', color: "#d72d36", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page58/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/6.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page58/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/10.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page58/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/14.jpg', input: true, answer: "E" },
        { url: 'img/FriendsPlus/Page58/E2/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/18.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page58/E2/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/22.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page58/E2/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E2/24.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P58-E3',
    audio: '',
    video: '',
    component: D1,
    fontSize: 21,
    exerciseKey: 'img/FriendsPlus/Page58/E3/Key/Key.png',
    // titleImage: 'http://',
    // titleQuestion: [{ num: '3', title: 'Read the text again. Complete the sentence with one or two words from the text.', color: "#d72d36", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page58/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/6.jpg', input: true, answer: "school" },
        { url: 'img/FriendsPlus/Page58/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/10.jpg', input: true, answer: "two" },
        { url: 'img/FriendsPlus/Page58/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/14.jpg', input: true, answer: "outdoor clothes" },
        { url: 'img/FriendsPlus/Page58/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/18.jpg', input: true, answer: "water bottles" },
        { url: 'img/FriendsPlus/Page58/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/22.jpg', input: true, answer: "tree" },
        { url: 'img/FriendsPlus/Page58/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E3/24.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P58-E4',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page58/E4/Key.png',
    // titleImage: 'img/FriendsPlus/Page58/E4/1.jpg',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#d72d36', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p> What is the teacher writing about? </p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>The plans for the adventure day.</u></p>" +
          "<p><span style='font-weight:600'>1</span>.  Why do the students need to be at the school at 7.45?  # </p>" +
          "<p><span style='font-weight:600'>2</span>.  Who is going to teach the students about tree climbing?  # </p>" +
          "<p><span style='font-weight:600'>3</span>.  Where are they going to walk after lunch?   # </p>" +
          "<p><span style='font-weight:600'>4</span>.  What time are the students going to be back at their school?  # </p>" +
          "<p><span style='font-weight:600'>5</span>.   Do you like the sound of the adventure day? Why / Why not?  # </p>",


        answer: [
          "Because they need time for all the children and teachers to get on the buses.",
          "Some special teachers at the centre are going to teach them about tree climbing.",
          "They're going to walk in the huge woods around the centre. / They're going to walk in the woods around the centre.",
          "They're going to be back at around 5.15.",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P58-E5',
    audio: '',
    video: '',
    component: D1,
    fontSize: 25,
    exerciseKey: 'img/FriendsPlus/Page58/E5/Key/Key.png',
    // titleImage: 'http://',
    titleQuestion: [{ num: '5', title: ' VOCABULARY PLUS Complete the dialogues with the given words.', color: "#d72d36", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page58/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page58/E5/3.jpg', input: true, answer: "awesome" },
        { url: 'img/FriendsPlus/Page58/E5/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page58/E5/7.jpg', input: true, answer: "expensive" },
        { url: 'img/FriendsPlus/Page58/E5/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page58/E5/11.jpg', input: true, answer: "new" },
        { url: 'img/FriendsPlus/Page58/E5/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page58/E5/15.jpg', input: true, answer: "boring" },
        { url: 'img/FriendsPlus/Page58/E5/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/18.jpg' },
        { url: 'img/FriendsPlus/Page58/E5/19.jpg', input: true, answer: "magnificent" },
        { url: 'img/FriendsPlus/Page58/E5/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page58/E5/21.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}
export default json;