
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {

  1: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P47-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page47/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page47/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/3.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page47/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page47/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page47/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/7.jpg', input: true, answer: "1" },
        { url: 'img/FriendsPlus/Page47/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page47/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page47/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/11.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page47/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page47/E1/13.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P47-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page47/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page47/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/3.jpg', input: true, answer: "He was born on" },
        { url: 'img/FriendsPlus/Page47/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page47/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page47/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/7.jpg', input: true, answer: "first played football" },
        { url: 'img/FriendsPlus/Page47/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page47/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page47/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/11.jpg', input: true, answer: "at the age of" },
        { url: 'img/FriendsPlus/Page47/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page47/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page47/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/15.jpg', input: true, answer: "continued to play" },
        { url: 'img/FriendsPlus/Page47/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page47/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page47/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/19.jpg', input: true, answer: "after that he won" },
        { url: 'img/FriendsPlus/Page47/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page47/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page47/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/23.jpg', input: true, answer: "He is one of the" },
        { url: 'img/FriendsPlus/Page47/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page47/E2/25.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page47/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/27.jpg', input: true, answer: "Neymar first played football when he was a young boy …" },
        { url: 'img/FriendsPlus/Page47/E2/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page47/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page47/E2/30.jpg', input: true, answer: "Neymar first played for Brazil …" },
        { url: 'img/FriendsPlus/Page47/E2/31.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P47-E3',
    audio: '',
    video: '',
    fontSize: 22,
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page47/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E3/3.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page47/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/6.jpg', input: true, answer: "British" },
        { url: 'img/FriendsPlus/Page47/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/8.jpg', input: true, answer: "was born" },
        { url: 'img/FriendsPlus/Page47/E3/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page47/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/11.jpg', input: true, answer: "1997" },
        { url: 'img/FriendsPlus/Page47/E3/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page47/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/14.jpg', input: true, answer: "United Kingdom" },
        { url: 'img/FriendsPlus/Page47/E3/15.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page47/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/17.jpg', input: true, answer: "sisters" },
        { url: 'img/FriendsPlus/Page47/E3/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page47/E3/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page47/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/21.jpg', input: true, answer: "was six" },
        { url: 'img/FriendsPlus/Page47/E3/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page47/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/24.jpg', input: true, answer: "was eleven" },
        { url: 'img/FriendsPlus/Page47/E3/25.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page47/E3/26.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page47/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/28.jpg', input: true, answer: "British gymnastics team" },
        { url: 'img/FriendsPlus/Page47/E3/29.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page47/E3/30.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page47/E3/31.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/32.jpg', input: true, answer: "four gold medals" },
        { url: 'img/FriendsPlus/Page47/E3/33.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page47/E3/34.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page47/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/36.jpg', input: true, answer: "2014" },
        { url: 'img/FriendsPlus/Page47/E3/37.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page47/E3/38.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/39.jpg', input: true, answer: "most successful" },
        { url: 'img/FriendsPlus/Page47/E3/40.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page47/E3/41.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page47/E3/42.jpg' },
        { url: 'img/FriendsPlus/Page47/E3/43.jpg', input: true, answer: "2014" },
        { url: 'img/FriendsPlus/Page47/E3/44.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page47/E3/45.jpg' },
      ],




    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P47-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Imagine you are a famous sports star. Write a short article about your life using ‘I’. Use the factfile and text in exercise 3 to help you.', color: "#00A851", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },



}

export default json;