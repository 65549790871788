import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  8: {
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P65-E8',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/E8/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page65/E8/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/05.jpg' },
        { url: 'img/FriendsPlus/Page65/E8/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page65/E8/07.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page65/E8/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page65/E8/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/10.jpg' },
        { url: 'img/FriendsPlus/Page65/E8/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page65/E8/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page65/E8/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page65/E8/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/15.jpg' },
        { url: 'img/FriendsPlus/Page65/E8/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page65/E8/17.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page65/E8/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page65/E8/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/21.jpg' },
        { url: 'img/FriendsPlus/Page65/E8/22.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page65/E8/23.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page65/E8/24.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page65/E8/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/26.jpg' },
        { url: 'img/FriendsPlus/Page65/E8/27.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page65/E8/28.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page65/E8/29.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page65/E8/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/31.jpg' },
        { url: 'img/FriendsPlus/Page65/E8/32.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page65/E8/33.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page65/E8/34.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page65/E8/35.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page65/E8/36.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  9: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P65-E9',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page65/E9/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E9/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page65/E9/2.jpg' },
        { url: 'img/FriendsPlus/Page65/E9/3.jpg', input: true, answer: "'ve got" },
        { url: 'img/FriendsPlus/Page65/E9/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page65/E9/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page65/E9/6.jpg' },
        { url: 'img/FriendsPlus/Page65/E9/7.jpg', input: true, answer: "'ve got" },
        { url: 'img/FriendsPlus/Page65/E9/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page65/E9/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page65/E9/10.jpg' },
        { url: 'img/FriendsPlus/Page65/E9/11.jpg', input: true, answer: "'s got" },
        { url: 'img/FriendsPlus/Page65/E9/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page65/E9/13.jpg' },
        { url: 'img/FriendsPlus/Page65/E9/14.jpg', input: true, answer: "'ve got" },
        { url: 'img/FriendsPlus/Page65/E9/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page65/E9/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page65/E9/17.jpg' },
        { url: 'img/FriendsPlus/Page65/E9/18.jpg', input: true, answer: "'s got" },
        { url: 'img/FriendsPlus/Page65/E9/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page65/E9/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page65/E9/21.jpg' },
        { url: 'img/FriendsPlus/Page65/E9/22.jpg', input: true, answer: "'ve got" },
        { url: 'img/FriendsPlus/Page65/E9/23.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  10: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P65-E10',
    audio: '',
    video: '',
    component: T6,
    inputSize: 650,
    imgSize: '70%',
    exerciseKey: 'img/FriendsPlus/Page65/E10/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page65/E10/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>I / new mobile phone cover</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I’ve got a new mobile phone cover.</u></p>" +

          "<p><span style='font-weight:600'>1</span>.  she / three sisters  </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>2</span>.  you / not / a notebook  </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>3</span>.  we / a friend in Spain  </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>4</span>.  he / not / a big desk  </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>5</span>.  Jasmine and Ava / a nice teacher  </p>" +
          "<p>#</p>" +
          "<p><span style='font-weight:600'>6</span>.  I / not / a good camera  </p>" +
          "<p>#</p>",

        answer: [
          "She's got three sisters.",
          "You haven't got a notebook.",
          "We've got a friend in Spain.",
          "He hasn't got a big desk.",
          "Jasmine and Ava have got a nice teacher.",
          "I haven't got a good camera.",

        ],
        type: 'longAnwser'
      },
    ]
  },
  11: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P65-E11',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page65/E11/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E11/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page65/E11/2.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/3.jpg', input: true, answer: "Have you got a sister?" },
        { url: 'img/FriendsPlus/Page65/E11/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page65/E11/5.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/6.jpg', input: true, answer: "have" },
        { url: 'img/FriendsPlus/Page65/E11/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page65/E11/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page65/E11/9.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/10.jpg', input: true, answer: "Has your friend got a pet?" },
        { url: 'img/FriendsPlus/Page65/E11/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page65/E11/12.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/13.jpg', input: true, answer: "hasn't" },
        { url: 'img/FriendsPlus/Page65/E11/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page65/E11/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page65/E11/16.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/17.jpg', input: true, answer: "Has Amira got two brothers?" },
        { url: 'img/FriendsPlus/Page65/E11/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page65/E11/19.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/20.jpg', input: true, answer: "has" },
        { url: 'img/FriendsPlus/Page65/E11/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page65/E11/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page65/E11/23.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/24.jpg', input: true, answer: "Have they got a nice house?" },
        { url: 'img/FriendsPlus/Page65/E11/25.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page65/E11/26.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/27.jpg', input: true, answer: "have" },
        { url: 'img/FriendsPlus/Page65/E11/28.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page65/E11/29.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page65/E11/30.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/31.jpg', input: true, answer: "Have we got a new teacher?" },
        { url: 'img/FriendsPlus/Page65/E11/32.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page65/E11/33.jpg' },
        { url: 'img/FriendsPlus/Page65/E11/34.jpg', input: true, answer: "haven't" },
        { url: 'img/FriendsPlus/Page65/E11/35.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  12: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P65-E12',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page65/E12/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E12/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page65/E12/2.jpg' },
        { url: 'img/FriendsPlus/Page65/E12/3.jpg', input: true, answer: "Who" },
        { url: 'img/FriendsPlus/Page65/E12/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page65/E12/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page65/E12/6.jpg' },
        { url: 'img/FriendsPlus/Page65/E12/7.jpg', input: true, answer: "Where" },
        { url: 'img/FriendsPlus/Page65/E12/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page65/E12/9.jpg' },
        { url: 'img/FriendsPlus/Page65/E12/10.jpg', input: true, answer: "What" },
        { url: 'img/FriendsPlus/Page65/E12/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page65/E12/12.jpg' },
        { url: 'img/FriendsPlus/Page65/E12/13.jpg', input: true, answer: "Where" },
        { url: 'img/FriendsPlus/Page65/E12/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page65/E12/15.jpg' },
        { url: 'img/FriendsPlus/Page65/E12/16.jpg', input: true, answer: "What" },
        { url: 'img/FriendsPlus/Page65/E12/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page65/E12/18.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  13: { // Exercise num
    unit: 'Language Focus Practice Starter unit',
    id: 'WB6-LFP-P65-E13',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page65/E13/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E13/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page65/E13/2.jpg' },
        { url: 'img/FriendsPlus/Page65/E13/3.jpg', input: true, answer: "but" },
        { url: 'img/FriendsPlus/Page65/E13/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page65/E13/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page65/E13/6.jpg' },
        { url: 'img/FriendsPlus/Page65/E13/7.jpg', input: true, answer: "or" },
        { url: 'img/FriendsPlus/Page65/E13/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page65/E13/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page65/E13/10.jpg' },
        { url: 'img/FriendsPlus/Page65/E13/11.jpg', input: true, answer: "and" },
        { url: 'img/FriendsPlus/Page65/E13/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page65/E13/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page65/E13/14.jpg' },
        { url: 'img/FriendsPlus/Page65/E13/15.jpg', input: true, answer: "but" },
        { url: 'img/FriendsPlus/Page65/E13/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page65/E13/17.jpg' },
        { url: 'img/FriendsPlus/Page65/E13/18.jpg', input: true, answer: "or" },
        { url: 'img/FriendsPlus/Page65/E13/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page65/E13/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page65/E13/21.jpg' },
        { url: 'img/FriendsPlus/Page65/E13/22.jpg', input: true, answer: "and" },
        { url: 'img/FriendsPlus/Page65/E13/23.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



}

export default json;