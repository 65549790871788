
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {
    1: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P45-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page45/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page45/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page45/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/3.jpg', input: true, answer: "played" },
                { url: 'img/FriendsPlus/Page45/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page45/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/6.jpg', input: true, answer: "ago" },
                { url: 'img/FriendsPlus/Page45/E1/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page45/E1/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page45/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/10.jpg', input: true, answer: "watched" },
                { url: 'img/FriendsPlus/Page45/E1/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page45/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/13.jpg', input: true, answer: "last" },
                { url: 'img/FriendsPlus/Page45/E1/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page45/E1/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page45/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/17.jpg', input: true, answer: "went" },
                { url: 'img/FriendsPlus/Page45/E1/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page45/E1/19.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/20.jpg', input: true, answer: "July" },
                { url: 'img/FriendsPlus/Page45/E1/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page45/E1/22.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P45-E2',
        audio: '',
        video: '',
        imgSize: 800,
        component: T1,
        fontSize: 30,
        height: 35,
        exerciseKey: 'img/FriendsPlus/Page45/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page45/E2/title.jpg',
        titleQuestion: [{ num: '2', title: 'Write sentences with the past simple form using the given phrases.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p></p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u style='font-size: 30px'>Freddie jumped two metres.</u></p>" +
                    "<p>1. # </p>" +
                    "<p>2. # </p>" +
                    "<p>3. # </p>" +
                    "<p>4. # </p>" +
                    "<p>5. # </p>" +
                    "<p>6. # </p>",


                answer: [
                    "Grace ran 100 metres.",
                    "Tamer swam 200 metres.",
                    "Emily won a medal.",
                    "Anne and Vicky watched the competitions.",
                    "Jake and Ben played volleyball.",
                    "Kemal scored a goal.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P45-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page45/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page45/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page45/E3/3.jpg', input: true, answer: "when" },
                { url: 'img/FriendsPlus/Page45/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page45/E3/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page45/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page45/E3/7.jpg', input: true, answer: "ago" },
                { url: 'img/FriendsPlus/Page45/E3/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page45/E3/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page45/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page45/E3/11.jpg', input: true, answer: "last" },
                { url: 'img/FriendsPlus/Page45/E3/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page45/E3/13.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page45/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page45/E3/15.jpg', input: true, answer: "week" },
                { url: 'img/FriendsPlus/Page45/E3/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page45/E3/17.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page45/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page45/E3/19.jpg', input: true, answer: "in" },
                { url: 'img/FriendsPlus/Page45/E3/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page45/E3/21.jpg' },
                { url: 'img/FriendsPlus/Page45/E3/22.jpg', input: true, answer: "first" },
                { url: 'img/FriendsPlus/Page45/E3/23.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page45/E3/24.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P45-E4',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page45/E4/title.jpg',
        titleQuestion: [{ num: '4', title: 'Write sentences that are true for you using the past simple. Use the given verbs and past time expressions to help you.', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I went to my friend’s house last Saturday and we played football.</u></p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;