import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';

import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit : 'Unit 7',
    id : 'WB6-U7-P49-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page49/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/3.jpg', input: true, answer: "visited" },
        { url: 'img/FriendsPlus/Page49/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/7.jpg', input: true, answer: "took" },
        { url: 'img/FriendsPlus/Page49/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page49/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/11.jpg', input: true, answer: "didn't live" },
        { url: 'img/FriendsPlus/Page49/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page49/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page49/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/15.jpg', input: true, answer: "didn't visit" },
        { url: 'img/FriendsPlus/Page49/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page49/E1/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page49/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/19.jpg', input: true, answer: "didn't take" },
        { url: 'img/FriendsPlus/Page49/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page49/E1/21.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit : 'Unit 7',
    id : 'WB6-U7-P49-E2',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page49/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Choose the correct answers.', color: '#3B3074' }],
    titleImage: 'img/FriendsPlus/Page49/E2/title.jpg',
    marginLeft: 255,
    widthButton: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'It was raining so I ....... to the park.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "didn’t go" },
          { image: "", isCorrect: false, right: "", text: "didn’t went" },
          { image: "", isCorrect: false, right: "", text: "not go" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'We ....... some beautiful places on holiday last year.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "did see" },
          { image: "", isCorrect: true, right: "", text: "saw" },
          { image: "", isCorrect: false, right: "", text: "visit" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'Yesterday I ....... my friends in town and we went to the café.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "meet" },
          { image: "", isCorrect: false, right: "", text: "did meet" },
          { image: "", isCorrect: true, right: "", text: "met" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'Harry ....... the homework so I gave him my notes.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "not understand" },
          { image: "", isCorrect: true, right: "", text: "didn’t understand" },
          { image: "", isCorrect: false, right: "", text: "understood" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `Esme came to my house for dinner last night. She’s vegetarian, so we ........ chicken.`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "didn’t cook" },
          { image: "", isCorrect: false, right: "", text: "cooked" },
          { image: "", isCorrect: false, right: "", text: "doesn’t cook" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `We ....... in a village when I was younger. It was very quiet. I prefer living in the city.`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "live" },
          { image: "", isCorrect: true, right: "", text: "lived" },
          { image: "", isCorrect: false, right: "", text: "lives" },
        ],
        no: 6,
        question: '',
        type: '',
      },

    ]
  },
  3: { // Exercise num
    unit : 'Unit 7',
    id : 'WB6-U7-P49-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page49/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/3.jpg', input: true, answer: "enjoyed" },
        { url: 'img/FriendsPlus/Page49/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/7.jpg', input: true, answer: "didn't study" },
        { url: 'img/FriendsPlus/Page49/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/10.jpg', input: true, answer: "saw" },
        { url: 'img/FriendsPlus/Page49/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page49/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page49/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/14.jpg', input: true, answer: "didn't phone" },
        { url: 'img/FriendsPlus/Page49/E3/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page49/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/17.jpg', input: true, answer: "ate" },
        { url: 'img/FriendsPlus/Page49/E3/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page49/E3/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page49/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/21.jpg', input: true, answer: "didn't visit" },
        { url: 'img/FriendsPlus/Page49/E3/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page49/E3/23.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page49/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/25.jpg', input: true, answer: "didn't teach" },
        { url: 'img/FriendsPlus/Page49/E3/26.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page49/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page49/E3/28.jpg', input: true, answer: "had" },
        { url: 'img/FriendsPlus/Page49/E3/29.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page49/E3/30.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit : 'Unit 7',
    id : 'WB6-U7-P49-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page49/E4/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/3.jpg', input: true, answer: "thought" },
        { url: 'img/FriendsPlus/Page49/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/5.jpg', input: true, answer: "saw" },
        { url: 'img/FriendsPlus/Page49/E4/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/8.jpg', input: true, answer: "shouted" },
        { url: 'img/FriendsPlus/Page49/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/10.jpg', input: true, answer: "didn't answer" },
        { url: 'img/FriendsPlus/Page49/E4/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/13.jpg', input: true, answer: "didn't hear" },
        { url: 'img/FriendsPlus/Page49/E4/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/16.jpg', input: true, answer: "met" },
        { url: 'img/FriendsPlus/Page49/E4/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page49/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/19.jpg', input: true, answer: "tried" },
        { url: 'img/FriendsPlus/Page49/E4/20.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page49/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/22.jpg', input: true, answer: "ate" },
        { url: 'img/FriendsPlus/Page49/E4/23.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page49/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/25.jpg', input: true, answer: "didn't enjoy" },
        { url: 'img/FriendsPlus/Page49/E4/26.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit : 'Unit 7',
    id : 'WB6-U7-P49-E5',
    audio: '',
    video: '',
    component: T1,
    fontSize: 28,
    exerciseKey: 'img/FriendsPlus/Page49/E5/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page49/E5/title.jpg',
    titleQuestion: [{ num: '5', title: 'Georgia needed to do different things yesterday, but she didn’t do them all. Write sentences using the affirmative and negative forms of the past simple.', color: "#1ABED7", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p></p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u style='font-size: 35px'>She finished the science project.</u></p>" +
          "<p></p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u style='font-size: 35px'>She didn’t phone Melisa.</u></p>" +
          "<p>1. #. </p>" +
          "<p>2. #. </p>" +
          "<p>3. #. </p>" +
          "<p>4. #. </p>" +
          "<p>5. #. </p>" +
          "<p>6. #. </p>",

        answer: [
          "She revised for the maths exam",
          "She didn't make notes about Việt Nam",
          "She took some photos for art",
          "She didn't help with the housework",
          "She played tennis with Samira",
          "She didn't read more of that new book",

        ],
        type: 'longAnwser'
      },
    ]
  },



}

export default json;