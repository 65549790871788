
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox2';

const json = {
  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P4-E1',
    audio: '',
    video: '',
    component: WordsBox,
    isAutofocus: true,
    typeInput: 'center',
    fontSize: 30,
    height: 50,
    paddingLeft: 3,
    exerciseKey: 'img/FriendsPlus/Page4/E1/Key/key.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page4/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/3.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page4/E1/4.jpg', input: true, answer: 'k' },
        { url: 'img/FriendsPlus/Page4/E1/5.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/7.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page4/E1/8.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page4/E1/9.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page4/E1/10.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/11.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page4/E1/12.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page4/E1/13.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page4/E1/14.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page4/E1/15.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page4/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/18.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/19.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page4/E1/20.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page4/E1/21.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page4/E1/22.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/24.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page4/E1/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/27.jpg', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page4/E1/28.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page4/E1/29.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page4/E1/30.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page4/E1/31.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page4/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/33.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/34.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page4/E1/35.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page4/E1/36.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page4/E1/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/39.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page4/E1/40.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page4/E1/41.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/42.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page4/E1/43.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page4/E1/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/45.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/46.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page4/E1/47.jpg', input: true, answer: 'y' },
        { url: 'img/FriendsPlus/Page4/E1/48.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/49.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page4/E1/50.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page4/E1/51.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page4/E1/52.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page4/E1/53.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/54.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/55.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page4/E1/56.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page4/E1/57.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page4/E1/58.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/59.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page4/E1/60.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page4/E1/61.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page4/E1/62.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/63.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page4/E1/64.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page4/E1/65.jpg', input: true, answer: 'y' },
        { url: 'img/FriendsPlus/Page4/E1/66.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/67.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/68.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/69.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page4/E1/70.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page4/E1/71.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page4/E1/72.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page4/E1/73.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page4/E1/74.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page4/E1/75.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page4/E1/76.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/77.jpg' },
      ]
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P4-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page4/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page4/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page4/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/3.jpg', input: true, answer: "cycling" },
        { url: 'img/FriendsPlus/Page4/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page4/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/6.jpg', input: true, answer: "photography" },
        { url: 'img/FriendsPlus/Page4/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page4/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page4/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/10.jpg', input: true, answer: "reading" },
        { url: 'img/FriendsPlus/Page4/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page4/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/13.jpg', input: true, answer: "friends" },
        { url: 'img/FriendsPlus/Page4/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page4/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/16.jpg', input: true, answer: "games" },
        { url: 'img/FriendsPlus/Page4/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page4/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/19.jpg', input: true, answer: "internet" },
        { url: 'img/FriendsPlus/Page4/E2/20.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P4-E3',
    audio: '',
    video: '',
    component: WordsBox,
    isAutofocus: true,
    fontSize: 29,
    // paddingLeft: 7,
    typeInput: 'center',
    exerciseKey: 'img/FriendsPlus/Page4/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page4/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page4/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/3.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page4/E3/4.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page4/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/6.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page4/E3/7.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page4/E3/8.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page4/E3/9.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page4/E3/10.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page4/E3/11.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page4/E3/12.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page4/E3/13.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page4/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/15.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page4/E3/16.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page4/E3/17.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page4/E3/18.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page4/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/20.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page4/E3/21.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page4/E3/22.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page4/E3/23.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page4/E3/24.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page4/E3/25.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page4/E3/26.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page4/E3/27.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page4/E3/28.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/29.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page4/E3/30.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page4/E3/31.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page4/E3/32.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page4/E3/33.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page4/E3/34.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page4/E3/35.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page4/E3/36.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/37.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page4/E3/38.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page4/E3/39.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page4/E3/40.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page4/E3/41.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page4/E3/42.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page4/E3/43.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page4/E3/44.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/45.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page4/E3/46.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page4/E3/47.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page4/E3/48.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page4/E3/49.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page4/E3/50.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page4/E3/51.jpg' },
        { url: 'img/FriendsPlus/Page4/E3/52.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page4/E3/53.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page4/E3/54.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page4/E3/55.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page4/E3/56.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page4/E3/57.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page4/E3/58.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page4/E3/59.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P4-E4',
    audio: '',
    video: '',
    imgSize: 900,
    exerciseKey: 'img/FriendsPlus/Page4/E4/Key/answerKey.png',
    component: T6,
    titleImage: 'img/FriendsPlus/Page4/E4/title.png',
    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> I’m interested # animals and music.</p>" +
          "<p><span style='font-weight:600'>2</span> James is # films and shopping.</p>" +
          "<p><span style='font-weight:600'>3</span> My dad is good # cooking.</p>" +
          "<p><span style='font-weight:600'>4</span> I’m # interested in reading.</p>" +
          "<p><span style='font-weight:600'>5</span> My # sport is basketball.</p>" +
          "<p><span style='font-weight:600'>6</span> I # like chatting on the internet.</p>",
        answer: [
          "in",
          "into",
          "at",
          "not",
          "favourite",
          "don't",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P4-E5',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: 'img/FriendsPlus/Page4/E5/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>My mum is interested in art, music and animals.</u></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },



}

export default json;