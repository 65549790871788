import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Wordbox from '../../components/ExcerciseTypes/Design/WordBox2';
import D_none from '../../components/ExcerciseTypes/Design/TypeIn_none';



const json = {

    1: {
        unit: 'Unit 5',
        id: 'WB6-U5-P38-E1',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page38/E1/Key/Key.png',
        component: Wordbox,
        isAutofocus: true,
        typeInput: 'center',
        fontSize: 25,
        // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page38/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/3.jpg', input: true, answer: "c" },
                { url: 'img/FriendsPlus/Page38/E1/4.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page38/E1/5.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page38/E1/6.jpg', input: true, answer: "v" },
                { url: 'img/FriendsPlus/Page38/E1/7.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page38/E1/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/10.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page38/E1/11.jpg', input: true, answer: "z" },
                { url: 'img/FriendsPlus/Page38/E1/12.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page38/E1/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/14.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/15.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/16.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page38/E1/17.jpg', input: true, answer: "f" },
                { url: 'img/FriendsPlus/Page38/E1/18.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page38/E1/19.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page38/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/21.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page38/E1/22.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page38/E1/23.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page38/E1/24.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page38/E1/25.jpg', input: true, answer: "h" },
                { url: 'img/FriendsPlus/Page38/E1/26.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page38/E1/27.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/28.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/29.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page38/E1/30.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page38/E1/31.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/32.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/33.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page38/E1/34.jpg', input: true, answer: "h" },
                { url: 'img/FriendsPlus/Page38/E1/35.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page38/E1/36.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page38/E1/37.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page38/E1/38.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page38/E1/39.jpg', input: true, answer: "h" },
                { url: 'img/FriendsPlus/Page38/E1/40.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page38/E1/41.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/42.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/43.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/44.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page38/E1/45.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page38/E1/46.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page38/E1/47.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/48.jpg', input: true, answer: "u" },
                { url: 'img/FriendsPlus/Page38/E1/49.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page38/E1/50.jpg', input: true, answer: "g" },
                { url: 'img/FriendsPlus/Page38/E1/51.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page38/E1/52.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page38/E1/53.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/54.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/55.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/56.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page38/E1/57.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page38/E1/58.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/59.jpg', input: true, answer: "h" },
                { url: 'img/FriendsPlus/Page38/E1/60.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page38/E1/61.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page38/E1/62.jpg', input: true, answer: "s" },
                { url: 'img/FriendsPlus/Page38/E1/63.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page38/E1/64.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page38/E1/65.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit: 'Unit 5',
        id: 'WB6-U5-P38-E2',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page38/E2/Key/Key.png',
        imgSize: '70%',
        inputSize: 120,
        titleImage: 'img/FriendsPlus/Page38/E2/title.jpg',
        // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>‘Is your grandfather very &#160;&#160; <u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>active</u>&#160;&#160;?’</p>" +
                    "<p style='font-weight:500'>‘Yes, he goes out for a walk every day.’</p>" +
                    "<p><span style='font-weight:600'>1 </span> ‘Look, Paul is sitting down.’ ‘Is he # after the  </p>" +
                    "<p>&#160;&#160; game of football?’</p>" +
                    "<p><span style='font-weight:600'>2 </span>‘Can you run up this mountain with me?’ ‘No! I’m very</p>" +
                    "<p>&#160; # because I never do any exercise.’</p>" +
                    "<p><span style='font-weight:600'>3 </span>‘Do you want a drink?’ ‘Yes, please. I’m very # .’</p>" +
                    "<p><span style='font-weight:600'>4 </span>‘Fatma isn’t at school.’ ‘Is she # ?’</p>" +
                    "<p><span style='font-weight:600'>5 </span>‘Is this drink # ?’ ‘No, it’s very bad for you.’</p>" +
                    "<p><span style='font-weight:600'>6 </span>‘My brother never helps with the housework!’</p>" +
                    "<p>&#160;&#160; ‘Is he always # at home?’</p>" +
                    "<p><span style='font-weight:600'>7 </span>‘I’m really # !’ ‘It’s OK. I can make you a </p>" +
                    "<p>&#160;&#160; sandwich.’</p>" +
                    "<p><span style='font-weight:600'>8 </span>‘Is Sifa # ?’ ‘Yes, she swims and plays basketball </p>" +
                    "<p>&#160;&#160; every week.</p>",
                answer: [
                    "tired",
                    "unfit",
                    "thirsty",
                    "ill",
                    "healthy",
                    "lazy",
                    "hungry",
                    "fit",
                ],
                type: 'longAnwser'
            },
        ]
    },

    3: {
        unit: 'Unit 5',
        id: 'WB6-U5-P38-E3',
        audio: 'img/FriendsPlus/Page38/E3/Audio/Friends Plus for Vietnam G6 WB Track 06.mp3',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page38/E3/Key/Key.png',
        component: D_none,
        paddingLeft: 12,
        fontSize: 29,
        // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page38/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/3.jpg', input: true, answer: "none" },
                { url: 'img/FriendsPlus/Page38/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/6.jpg', input: true, answer: "B" },
                { url: 'img/FriendsPlus/Page38/E3/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/9.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/10.jpg', input: true, answer: "B" },
                { url: 'img/FriendsPlus/Page38/E3/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/13.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/14.jpg', input: true, answer: "none" },
                { url: 'img/FriendsPlus/Page38/E3/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/16.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/17.jpg', input: true, answer: "P" },
                { url: 'img/FriendsPlus/Page38/E3/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/19.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/20.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/21.jpg', input: true, answer: "P" },
                { url: 'img/FriendsPlus/Page38/E3/22.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/23.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/24.jpg', input: true, answer: "B" },
                { url: 'img/FriendsPlus/Page38/E3/25.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/26.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/27.jpg', input: true, answer: "none" },
                { url: 'img/FriendsPlus/Page38/E3/28.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: {
        unit: 'Unit 5',
        id: 'WB6-U5-P38-E4',
        audio: 'img/FriendsPlus/Page38/E4/Audio/Friends Plus for Vietnam G6 WB Track 06.mp3',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page38/E4/Key/Key.png',
        component: D1,
        fontSize: 25,
        // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page38/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/3.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page38/E4/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/5.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/6.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page38/E4/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/9.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/10.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page38/E4/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/13.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/14.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page38/E4/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/17.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page38/E4/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E4/19.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/20.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page38/E4/21.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    5: { // Exercise num
        unit: 'Unit 5',
        id: 'WB6-U5-P38-E5',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: '',
        titleQuestion: [{ num: '5', title: 'Complete the sentences.', color: "#e38e35", star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>When I’m ill, &#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>I sometimes go to the doctor.</u></p>" +
                    "<p>I’m unfit because&#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>I don't do any exercise.</u></p>" +
                    "<p><span style='font-weight:600'>1 </span>I’m fit / unfit because I </p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>2 </span>When I’m hungry, I</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>3 </span>When I’m thirsty, I</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>4 </span>If I’m tired, I</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>5 </span>I eat healthy food like </p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>6 </span>I sometimes eat unhealthy food such as</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
}
export default json