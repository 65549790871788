/* eslint-disable no-unused-expressions */
import React from 'react';
import { Form, Button } from 'antd';
import PropTypes from 'prop-types';
import { Player } from 'video-react';
import styles from './styles.module.css';
import 'video-react/dist/video-react.css';
import ReactHtmlParser from 'react-html-parser';
import { Row, Input, Container, Col } from 'reactstrap';
import TitleQuestion from '../../../TitleQuestion';
import FooterIeltsMindset from '../../../FooterModal';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const TypeIn6 = (props) => {

  const inputTag = '#';

  const FormItem = Form.Item;

  const submitButton = React.useRef();

  const refForm = React.useRef();

  const inputCount = React.useRef(0);

  const [form] = Form.useForm();

  const [state, setState] = React.useState({
    audioUrl: null,
    sentences: null,
    maxInput: null,
    videoUrl: null,
    isPointed: false,
    videoVisible: false,
    isDisabledRetry: true,
  });

  React.useEffect(() => {
    const sentences = JSON.parse(JSON.stringify(props.questions));
    sentences.titleImage = props.titleImage
    sentences.titleQuestion = props.titleQuestion
    const audioUrl = props.audio
    const videoUrl = props.video
    const maxInput = sentences[0].answer.length
    setState((prevState) => ({ ...prevState, sentences, audioUrl, videoUrl, maxInput }))
  }, [props])


  const toggleState = React.useCallback((fieldName) => () => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  }, []);

  const onPlayVideo = React.useCallback(() => {
    toggleState('videoVisible')();
  }, [toggleState])


  // const onSubmit = React.useCallback(() => {
  //   submitButton.current?.click();
  // }, [])

  // const onRetry = React.useCallback(() => {
  //   form.resetFields();
  //   setState((preState) => ({ ...preState, isDisabledInput: false, isDisabledRetry: true, isPointed: false }))
  // }, [form])

  // Khi hoàn thành các field
  const onFinish = React.useCallback((value) => {
    let booleanArray = []
    state.sentences[0].answer.forEach((item, index) => {
      let isCorrect = false;
      const isManyAnswers = item.includes('/');
      if (isManyAnswers && value[index]) {
        const answers = item.split('/');
        for (let i = 0; i < answers.length; i++) {
          isCorrect = answers[i].trim().toLowerCase() === value[index].trim().toLowerCase();
          if (isCorrect) break;
        }
      } else {
        if (!item) {
          isCorrect = true;
        }
        else {
          console.log(value[index])
          if (value[index]) {
            isCorrect = item.trim().toLowerCase() === value[index].trim().toLowerCase()
          }
        }
      }
      booleanArray.push(isCorrect)
    })
    state.sentences.booleanArray = booleanArray
    setState((preState) => ({ ...preState, sentences: state.sentences, isDisabledRetry: true, isPointed: false }))
  }, [state])


  const transform = React.useCallback((node, index) => {

    if (node.type === 'text') {

      if (!node.data.includes(inputTag)) return;

      const elementArray = node.data.split(inputTag);

      let currentInputNo = 0;

      return (
        <span key={index} style={{ fontSize: 25 }}>
          {elementArray.map((item, index) => {
            if (index > 0) {

              currentInputNo = inputCount.current;

              const maxInput = state.sentences[0].answer.length

              inputCount.current++;

              if (inputCount.current >= maxInput) {
                inputCount.current = 0;
              }
            }
            let widthInput = 150;
            const type = state.sentences[0].type

            if (type === 'longAnwser') {
              widthInput = 300
            }
            if (type === 'longAnwserInLine') {
              widthInput = 750
            }

            if (props.inputSize) {
              widthInput = props.inputSize
            }

            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <FormItem
                    className='ml-2 mr-2'
                    name={currentInputNo}
                    style={{ display: 'inline-block', marginBottom: 0 }}

                  // rules={[{ required: true, message: 'Please fill the answer' },]}
                  >
                    <div>
                      <Input
                        maxLength={props.textCenter ? 1 : ''}
                        autoComplete='off'
                        style={{
                          height: 30,
                          fontSize: 26,
                          borderWidth: 0,
                          borderRadius: 0,
                          fontWeight: '500',
                          display: 'inline',
                          borderBottomWidth: 1,
                          width: widthInput,
                          borderStyle: 'dotted',
                          backgroundColor: 'white',
                          borderBottomColor: '#bdc3c7',
                          boxShadow: 'none',
                          textTransform: props.textCenter ? 'capitalize' : '',
                          textAlign: props.textCenter ? 'center' : '',
                          color: state.isPointed ? (state.sentences.booleanArray?.[currentInputNo] ? '#2ecc71' : '#e74c3c') : 'black',
                        }}
                        id={currentInputNo}
                        disabled={state.isPointed}
                        className={!state.isPointed ? styles.input : styles.checkInput}
                      />
                      {state.isPointed && state.sentences.booleanArray?.[currentInputNo] && (
                        <CheckCircleOutlined style={{ fontSize: 25, color: '#2ecc71' }} />
                      )}
                      {state.isPointed && !state.sentences.booleanArray?.[currentInputNo] && (
                        <CloseCircleOutlined style={{ fontSize: 25, color: '#e74c3c' }} />
                      )}
                    </div>
                  </FormItem>
                )}
                {item}
              </React.Fragment>
            )
          })}
        </span>
      )
    }
  }, [state.sentences, state.isPointed, props.inputSize, props.textCenter])

  if (!state.sentences) return null;

  return (
    <Container className='fluid'>
      <Row>
        <TitleQuestion titleQuestion={state.sentences.titleQuestion} />
      </Row>
      <Row className='d-flex justify-content-center'>
        {state.sentences.titleImage && (
          <img
            alt='...'
            style={{
              width: props?.imgSize ?? '100%',
              marginBottom: 10
            }}
            src={state.sentences.titleImage}
          />
        )}
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>

        <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {state.videoUrl && (
            <Row className='mb-4'>
              <Player
                // playsInline
                poster="/assets/poster.png"
                src={state.videoUrl}
              />
            </Row>
          )}

          <Form
            autoComplete="off"
            form={form}
            ref={refForm}
            onFinish={onFinish}
          >
            {ReactHtmlParser(state.sentences[0].title, { transform })}
            <FormItem>
              <Button
                style={{
                  display: 'none'
                }}
                ref={submitButton}
                id='submitButton'
                htmlType="submit" />
            </FormItem>
          </Form>
        </Col>

      </Row>

      <FooterIeltsMindset
        onRetry
        onPlayVideo={onPlayVideo}
        audioUrl={state.audioUrl}
        isDisabledSubmit
        isDisabledRetry={state.isDisabledRetry}
        hideBtnFooter={state.maxInput === 0}  //not answer
      />
    </Container>
  );
};
TypeIn6.propTypes = {
  question: PropTypes.instanceOf(Object),

}
export default TypeIn6;
