import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 2',
        id : 'WB6-CR-P60-E1',
        audio: '',
        video: '',
        imgSize: 1000,
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page60/E1/title.jpg',
        //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 2',
        id : 'WB6-CR-P60-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page60/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page60/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page60/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page60/E2/3.jpg', input: true, answer: "The clock on a bridge/The clock on a bridge." },
                { url: 'img/FriendsPlus/Page60/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page60/E2/5.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 2',
        id : 'WB6-CR-P60-E3',
        audio: '',
        video: '',
        fontSize:21,
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page60/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page60/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page60/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page60/E3/3.jpg', input: true, answer: "north" },
                { url: 'img/FriendsPlus/Page60/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page60/E3/5.jpg', input: true, answer: "two parades" },
                { url: 'img/FriendsPlus/Page60/E3/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page60/E3/7.jpg' },
                { url: 'img/FriendsPlus/Page60/E3/8.jpg', input: true, answer: "a big market" },
                { url: 'img/FriendsPlus/Page60/E3/9.jpg' },
                { url: 'img/FriendsPlus/Page60/E3/10.jpg', input: true, answer: "play music" },
                { url: 'img/FriendsPlus/Page60/E3/11.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page60/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page60/E3/13.jpg', input: true, answer: "a clock on a bridge" },
                { url: 'img/FriendsPlus/Page60/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page60/E3/15.jpg', input: true, answer: "dance in the streets" },
                { url: 'img/FriendsPlus/Page60/E3/16.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 2',
        id : 'WB6-CR-P60-E4',
        audio: 'img/FriendsPlus/Page60/E4/Audio/Friends Plus for Vietnam G6 WB Track 10.mp3',
        video: '',
        imgSize: 900,
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page60/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page60/E4/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title:

                    "<p>#</p>",

                answer: [
                    "They want to visit the clock and the castle.",
                ],
                type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 2',
        id : 'WB6-CR-P60-E5',
        audio: 'img/FriendsPlus/Page60/E5/Audio/Friends Plus for Vietnam G6 WB Track 10.mp3',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page60/E5/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page60/E5/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page60/E5/2.jpg' },
                { url: 'img/FriendsPlus/Page60/E5/3.jpg', input: true, answer: "castle" },
                { url: 'img/FriendsPlus/Page60/E5/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page60/E5/5.jpg' },
                { url: 'img/FriendsPlus/Page60/E5/6.jpg', input: true, answer: "map" },
                { url: 'img/FriendsPlus/Page60/E5/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page60/E5/8.jpg' },
                { url: 'img/FriendsPlus/Page60/E5/9.jpg', input: true, answer: "ten" },
                { url: 'img/FriendsPlus/Page60/E5/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page60/E5/11.jpg' },
                { url: 'img/FriendsPlus/Page60/E5/12.jpg', input: true, answer: "mum" },
                { url: 'img/FriendsPlus/Page60/E5/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page60/E5/14.jpg' },
                { url: 'img/FriendsPlus/Page60/E5/15.jpg', input: true, answer: "clock" },
                { url: 'img/FriendsPlus/Page60/E5/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page60/E5/17.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    6: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 2',
        id : 'WB6-CR-P60-E6',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page60/E6/title.jpg',
        //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;