import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'WB6-U8-P56-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 9,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page56/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page56/E1/08.jpg' },
      ],
      [

        { url: 'img/FriendsPlus/Page56/E1/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page56/E1/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/14.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page56/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/19.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page56/E1/20.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/22.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page56/E1/23.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/27.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page56/E1/28.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/30.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/31.jpg', input: 7, },
        { url: 'img/FriendsPlus/Page56/E1/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/35.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page56/E1/36.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/38.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page56/E1/39.jpg', input: 9, isCorrect: true },
        { url: 'img/FriendsPlus/Page56/E1/40.jpg' },

      ]

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P56-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page56/E2/Key/Key.png',
    // titleImage: 'http://',
    titleQuestion: [{ num: '2', title: 'Complete the dialogues with the adjectives in exercise 1.', color: "#f58024", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page56/E2/title.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/3.jpg', input: true, answer: "snowy" },
        { url: 'img/FriendsPlus/Page56/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/7.jpg', input: true, answer: "sunny" },
        { url: 'img/FriendsPlus/Page56/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/11.jpg', input: true, answer: "foggy" },
        { url: 'img/FriendsPlus/Page56/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/15.jpg', input: true, answer: "hot" },
        { url: 'img/FriendsPlus/Page56/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/19.jpg', input: true, answer: "rainy" },
        { url: 'img/FriendsPlus/Page56/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/23.jpg', input: true, answer: "icy" },
        { url: 'img/FriendsPlus/Page56/E2/24.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page56/E2/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P56-E3',
    audio: 'img/FriendsPlus/Page56/E3/Audio/Friends Plus for Vietnam G6 WB Track 09.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page56/E3/Key/Key.png',
    // titleImage: 'http://',
    // titleQuestion: [{ num: '3', title: ' Andy, Andy’s mum and Ben are talking about their holiday plans. Read the sentences first. Can you guess any of the answers? Then listen and answer the questions.', color: "#f58024", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page56/E3/title.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/3.jpg', input: true, answer: "19" },
        { url: 'img/FriendsPlus/Page56/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/7.jpg', input: true, answer: "sunny" },
        { url: 'img/FriendsPlus/Page56/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/11.jpg', input: true, answer: "foggy/really foggy" },
        { url: 'img/FriendsPlus/Page56/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/14.jpg', input: true, answer: "waterproofs" },
        { url: 'img/FriendsPlus/Page56/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/18.jpg', input: true, answer: "camping" },
        { url: 'img/FriendsPlus/Page56/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/22.jpg', input: true, answer: "hot" },
        { url: 'img/FriendsPlus/Page56/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/25.jpg', input: true, answer: "sunny" },
        { url: 'img/FriendsPlus/Page56/E3/26.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  // 4: { // Exercise num

  //   audio: '',
  //   video: '',
  //   component: D1,
  //   exerciseKey: 'img/FriendsPlus/Page56/E4/Key/Key.png',
  //   // titleImage: 'http://',
  //   titleQuestion: [{ num: '4', title: 'Listen again. Correct the information in the sentences.', color: "#f58024", star: 2 }],
  //   questionImage: [ // Row
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/1.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/2.jpg' },
  //       { url: 'img/FriendsPlus/Page56/E4/3.jpg', input: true, answer: "a year" },
  //       { url: 'img/FriendsPlus/Page56/E4/4.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/5.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/6.jpg' },
  //       { url: 'img/FriendsPlus/Page56/E4/7.jpg', input: true, answer: "often changes / changes a lot" },
  //       { url: 'img/FriendsPlus/Page56/E4/8.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/9.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/10.jpg' },
  //       { url: 'img/FriendsPlus/Page56/E4/11.jpg', input: true, answer: "April" },
  //       { url: 'img/FriendsPlus/Page56/E4/12.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/13.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/14.jpg' },
  //       { url: 'img/FriendsPlus/Page56/E4/15.jpg', input: true, answer: "rainy" },
  //       { url: 'img/FriendsPlus/Page56/E4/16.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/17.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/18.jpg' },
  //       { url: 'img/FriendsPlus/Page56/E4/19.jpg', input: true, answer: "south" },
  //       { url: 'img/FriendsPlus/Page56/E4/20.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page56/E4/21.jpg' },
  //       { url: 'img/FriendsPlus/Page56/E4/22.jpg', input: true, answer: "big" },
  //       { url: 'img/FriendsPlus/Page56/E4/23.jpg' },
  //     ],

  //   ],
  //   questions: [
  //     {
  //       title: 'The dictionaries are # the shelf',
  //       answer: ['table']
  //     },
  //   ]
  // },

  4: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P56-E4',
    audio: 'img/FriendsPlus/Page56/E4/Audio/Friends Plus for Vietnam G6 WB Track 09.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page56/E4/Key/Key.png',
    imgSize: '60%',
    inputSize: 190,
    // titleImage: 'img/FriendsPlus/Page52/E5/title.jpg',
    titleQuestion: [{ num: '4', title: 'Listen again. Correct the information in the sentences.', color: "#f58024", star: 2 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Ben is going to visit his grandmother’s <span style='text-decoration: line-through;'>brother</span>. &ensp;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> sister </u></p>" +
          "<p><span style='font-weight:600'>1 </span>Andy’s mum worked in San Francisco for two years. #</p>" +
          "<p><span style='font-weight:600'>2 </span>The weather in San Francisco never changes.  #</p>" +
          "<p><span style='font-weight:600'>3 </span>In San Francisco, it’s sunnier in December than in July. #</p>" +
          "<p><span style='font-weight:600'>4 </span>The weather is sometimes snowy in San Francisco too. #</p>" +
          "<p><span style='font-weight:600'>5 </span>Andy is going to stay in the north of Italy.  #</p>" +
          "<p><span style='font-weight:600'>6 </span>Andy’s tent is very small.   #</p>",
        answer: [
          "a year",
          "/often changes / changes a lot",
          "April",
          "rainy",
          "south",
          "big",
        ],
        type: 'longAnwser'
      },
    ]
  },


  5: { // Exercise num
    unit: 'Unit 8',
    id: 'WB6-U8-P56-E5',
    audio: '',
    video: '',
    component: T5,
    inputSize: 500,
    exerciseKey: '',
    // titleImage: 'img/FriendsPlus/Page56/E6/1.jpg',
    titleQuestion: [{ num: '5', title: 'Complete the sentences about the weather at different times.', color: '#f58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p >In the summer holidays, <span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px'>it's really hot and sunny .</span></p>" +
          "<p>1.  This morning / afternoon / evening,  # </p>" +
          "<p>2.  This week, # </p>" +
          "<p>3.  At New Year,  # </p>" +
          "<p>4.  On my birthday,  # </p>" +
          "<p>5.  In April,  # </p>" +
          "<p>6.  In the winter holidays, # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;