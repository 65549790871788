import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import Wordbox from '../../components/ExcerciseTypes/Design/WordBox2';


const json = {

  1: {
    unit: 'Unit 5',
    id: 'WB6-U5-P40-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 1,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page40/E1/03.jpg' },
      ],
      [

        { url: 'img/FriendsPlus/Page40/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/06.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page40/E1/07.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/08.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/09.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page40/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/12.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/14.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: {
    unit: 'Unit 5',
    id: 'WB6-U5-P40-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page40/E2/Key/Key.png',
    component: D1,
    fontSize: 25,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page40/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/3.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page40/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/6.jpg', input: true, answer: "E" },
        { url: 'img/FriendsPlus/Page40/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/9.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page40/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/12.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page40/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/15.jpg', input: true, answer: "A" },
        { url: 'img/FriendsPlus/Page40/E2/16.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: {
    unit: 'Unit 5',
    id: 'WB6-U5-P40-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page40/E3/Key/Key.png',
    component: D1,
    fontSize: 25,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page40/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/3.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page40/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/6.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page40/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/9.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page40/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/13.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page40/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/16.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page40/E3/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/20.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page40/E3/21.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P40-E4',
    audio: '',
    video: '',
    component: T1,
    fontSize: 28,
    height: 35,
    exerciseKey: 'img/FriendsPlus/Page40/E4/Key/Key.png',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: "#df2c37", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>What is Phoebe doing with the noodles?</p>" +
          "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>She’s cooking the noodles.</u></p>" +
          "<p><span style='font-weight:600'>1 </span>How old are the children on the TV show?</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>2 </span>How many children can go on <span style='font-style:Italic'>Junior Masterchef</span> every year?</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>3 </span>Why are the children working very fast?</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>4 </span> What does Phoebe make for one part of her meal?</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>5 </span>Do you watch TV programmes like <span style='font-style:Italic'>Junior Masterchef</span> ? Why / Why not?</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p><span style='font-weight:600'>6 </span>Do you want to go on a TV programme like this? Why / Why not?</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [
          "The children are aged eight to twelve.",
          "Twenty-four children can go on Junior Masterchef every year.",
          "They are working very fast because they haven't got much time – only two hours.",
          "She makes vegetable soup, pasta and a chocolate dessert.",
          "",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },

  5: {
    unit: 'Unit 5',
    id: 'WB6-U5-P40-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page40/E5/Key/Key.png',
    component: Wordbox,
    isAutofocus: true,
    typeInput: 'center',
    fontSize: 30,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page40/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E5/3.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page40/E5/4.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page40/E5/5.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page40/E5/6.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page40/E5/7.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page40/E5/8.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page40/E5/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page40/E5/12.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page40/E5/13.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page40/E5/14.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page40/E5/15.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page40/E5/16.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page40/E5/17.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page40/E5/18.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page40/E5/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/21.jpg' },
        { url: 'img/FriendsPlus/Page40/E5/22.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page40/E5/23.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page40/E5/24.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page40/E5/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/27.jpg' },
        { url: 'img/FriendsPlus/Page40/E5/28.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page40/E5/29.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page40/E5/30.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page40/E5/31.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page40/E5/32.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page40/E5/33.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page40/E5/34.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page40/E5/35.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/37.jpg' },
        { url: 'img/FriendsPlus/Page40/E5/38.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page40/E5/39.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page40/E5/40.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page40/E5/41.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E5/42.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}

export default json;