import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P24-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    inputSize: 400,
    exerciseKey: 'img/FriendsPlus/Page24/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page24/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page24/E1/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page24/E1/08.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E1/09.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page24/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page24/E1/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page24/E1/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page24/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page24/E1/19.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page24/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page24/E1/21.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E1/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/24.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page24/E1/25.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E1/26.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page24/E1/27.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page24/E1/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/30.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page24/E1/31.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page24/E1/32.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E1/33.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page24/E1/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/36.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page24/E1/37.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page24/E1/38.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page24/E1/39.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E1/40.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P24-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page24/E2/Key/Key.png',
    imgSize: '70%',
    inputSize: 600,
    titleImage: 'img/FriendsPlus/Page24/E2/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:600'><u>There are two ostriches.</u></p>" +
          "<p style='font-weight:500'>1.  #</p>" +
          "<p style='font-weight:500'>2. #</p>" +
          "<p style='font-weight:500'>3. #</p>" +
          "<p style='font-weight:500'>4. #</p>" +
          "<p style='font-weight:500'>5. #</p>" +
          "<p style='font-weight:500'>6. #</p>" +
          "<p style='font-weight:500'>7. #</p>" +
          "<p style='font-weight:500'>8. #</p>",
        answer: [
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
          "There are three elephants. / There's one giraffe. / There are four crocodiles. / There are two eagles. / There are five butterflies. / There are two snakes. / There are three frogs. / There's one scorpion.",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P24-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page24/E3/Key/answerKey.jpg',
    imgSize: '70%',
    titleImage: 'img/FriendsPlus/Page24/E3/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'>It lives in China. It’s black and white. It eats for more </p>" +
          "<p>than twelve hours every day!.....<span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>panda</span>.....  </p></br>" +
          "<p style='font-weight:500'>It lives in Africa. It’s yellow and brown. It’s very tall -</p>" +
          "<p>usually five metres or more!  <sup>1</sup>#</p></br>" +
          "<p style='font-weight:500'>It has got eight arms. It lives in the water. </p>" +
          "<p>When it goes to a different place, it sometimes changes </p>" +
          "<p>colour. <sup>2</sup>#</p></br>" +
          "<p style='font-weight:500'>It often lives in a hot place. It sometimes goes without</p>" +
          "<p> water for six months! <sup>3</sup>#</p></br>" +
          "<p style='font-weight:500'>It’s small and it usually lives in hot places. It has got a very </p>" +
          "<p>dangerous tail. <sup>4</sup>#</p></br>" +
          "<p style='font-weight:500'>It has got very big ears and a long nose. It sometimes lives</p>" +
          "<p> for seventy years! <sup>5</sup>#</p></br>" +
          "<p style='font-weight:500'>It has got eight legs. It often eats a lot of insects in one </p>" +
          "<p>day.  <sup>6</sup>#</p>",
        answer: [
          "giraffe",
          "octopus",
          "camel",
          "scorpion",
          "elephant",
          "spider",
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P24-E4',
    audio: '',
    video: '',
    component: D1,
    isHiddenCheck: true,
    height: 35,
    exerciseKey: 'img/FriendsPlus/Page24/E4/Key/Key.png',
    // titleImage: 'img/FriendsPlus/Page24/E4/title.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page24/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/3.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/5.jpg', input: true, answer: "octopus / whale" },
        { url: 'img/FriendsPlus/Page24/E4/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/8.jpg', input: true, answer: "bear / gorilla" },
        { url: 'img/FriendsPlus/Page24/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/10.jpg', input: true, answer: "octopus / whale" },
        { url: 'img/FriendsPlus/Page24/E4/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/13.jpg', input: true, answer: "bear / gorilla" },
        { url: 'img/FriendsPlus/Page24/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/20.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/23.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/25.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/27.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/28.jpg', input: true, answer: "bat" },
        { url: 'img/FriendsPlus/Page24/E4/29.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/30.jpg', input: true, answer: "eagle" },
        { url: 'img/FriendsPlus/Page24/E4/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/32.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/33.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/34.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/35.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/37.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/38.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/39.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/40.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/41.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page24/E4/42.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/43.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/44.jpg' },
        { url: 'img/FriendsPlus/Page24/E4/45.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page24/E4/46.jpg' },
      ],
    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}
export default json