
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';


const json = {

    1: { // Exercise num
        unit : 'Language Focus Practice Unit 4',
        id : 'WB6-LFP4-P69-E1',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page69/E1/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>ask<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'>  asking </span> </p>" +

                    "<p><span style='font-weight:600'>1</span> swim # &emsp;     <span style='font-weight:600'>5</span> sit #</p>" +
                    "<p><span style='font-weight:600'>2</span> drink # &emsp;     <span style='font-weight:600'>6</span> repeat #</p>" +
                    "<p><span style='font-weight:600'>3</span> run # &emsp;&ensp;&#160; <span style='font-weight:600'>7</span> survive #</p>" +
                    "<p><span style='font-weight:600'>4</span> practise #  <span style='font-weight:600'>8</span> fly #</p>",


                answer: [
                    "swimming ", "sitting ",
                    "drinking ", "repeating ",
                    "running ", "surviving ",
                    "practising ", "flying",



                ],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit : 'Language Focus Practice Unit 4',
        id : 'WB6-LFP4-P69-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page69/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page69/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page69/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page69/E2/3.jpg', input: true, answer: "'s washing" },
                { url: 'img/FriendsPlus/Page69/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page69/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page69/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page69/E2/7.jpg', input: true, answer: "'m not using" },
                { url: 'img/FriendsPlus/Page69/E2/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page69/E2/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page69/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page69/E2/11.jpg', input: true, answer: "isn't studying" },
                { url: 'img/FriendsPlus/Page69/E2/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page69/E2/13.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page69/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page69/E2/15.jpg', input: true, answer: "'m chatting" },
                { url: 'img/FriendsPlus/Page69/E2/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page69/E2/17.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page69/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page69/E2/19.jpg', input: true, answer: "isn't eating" },
                { url: 'img/FriendsPlus/Page69/E2/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page69/E2/21.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page69/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page69/E2/23.jpg', input: true, answer: "'re helping" },
                { url: 'img/FriendsPlus/Page69/E2/24.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit : 'Language Focus Practice Unit 4',
        id : 'WB6-LFP4-P69-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page69/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page69/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page69/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page69/E3/3.jpg', input: true, answer: "are you cooking" },
                { url: 'img/FriendsPlus/Page69/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page69/E3/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page69/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page69/E3/7.jpg', input: true, answer: "is she teaching" },
                { url: 'img/FriendsPlus/Page69/E3/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page69/E3/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page69/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page69/E3/11.jpg', input: true, answer: "is she singing" },
                { url: 'img/FriendsPlus/Page69/E3/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page69/E3/13.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page69/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page69/E3/15.jpg', input: true, answer: "are they going" },
                { url: 'img/FriendsPlus/Page69/E3/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page69/E3/17.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page69/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page69/E3/19.jpg', input: true, answer: "are they flying" },
                { url: 'img/FriendsPlus/Page69/E3/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page69/E3/21.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page69/E3/22.jpg' },
                { url: 'img/FriendsPlus/Page69/E3/23.jpg', input: true, answer: "is he studying" },
                { url: 'img/FriendsPlus/Page69/E3/24.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit : 'Language Focus Practice Unit 4',
        id : 'WB6-LFP4-P69-E4',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page69/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page69/E4/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>you / study / English and German ? ✔</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Are you studying English and German? Yes, I am.</u></p>" +

                    "<p><span style='font-weight:600'>1</span>  you / concentrate / on this TV programme / ? ✘ #</p>" +
                    "<p><span style='font-weight:600'>2</span>  we / practise / our pronunciation / ? ✔ #</p>" +
                    "<p><span style='font-weight:600'>3</span>  you / sit / in my chair / ? ✘ #</p>" +
                    "<p><span style='font-weight:600'>4</span>  your sister / make / some new clothes / ? ✔ #</p>" +
                    "<p><span style='font-weight:600'>5</span>  they / revise / for the history exam / ? ✔ #</p>" +
                    "<p><span style='font-weight:600'>6</span>  he / read / that book about animals / ? ✘ #</p>",
                answer: [
                    "Are you concentrating on this TV programme? No, I'm not. ",
                    "Are we practising our pronunciation? Yes, we are. ",
                    "Are you sitting in my chair? No, I'm not. ",
                    "Is your sister making some new clothes? Yes, she is. ",
                    "Are they revising for their history exam? Yes, they are. ",
                    "Is he reading that book about animals? No, he isn't.",



                ],
                type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit : 'Language Focus Practice Unit 4',
        id : 'WB6-LFP4-P69-E5',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page69/E5/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page69/E5/1.jpg'},
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page69/E5/2.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/3.jpg', input: true, answer: "watch"},
                { url: 'img/FriendsPlus/Page69/E5/4.jpg'},
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page69/E5/5.jpg'},
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page69/E5/6.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/7.jpg', input: true, answer: "'s playing"},
                { url: 'img/FriendsPlus/Page69/E5/8.jpg'},
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page69/E5/9.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/10.jpg', input: true, answer: "doesn't work"},
                { url: 'img/FriendsPlus/Page69/E5/11.jpg'},
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page69/E5/12.jpg'},
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page69/E5/13.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/14.jpg', input: true, answer: "plays"},
                { url: 'img/FriendsPlus/Page69/E5/15.jpg'},
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page69/E5/16.jpg'},
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page69/E5/17.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/18.jpg', input: true, answer: "talks"},
                { url: 'img/FriendsPlus/Page69/E5/19.jpg'},
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page69/E5/20.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/21.jpg', input: true, answer: "isn't working"},
                { url: 'img/FriendsPlus/Page69/E5/22.jpg'},
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page69/E5/23.jpg'},
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page69/E5/24.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/25.jpg', input: true, answer: "'re watching"},
                { url: 'img/FriendsPlus/Page69/E5/26.jpg'},
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page69/E5/27.jpg'},
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page69/E5/28.jpg'},
                { url: 'img/FriendsPlus/Page69/E5/29.jpg', input: true, answer: "'s talking"},
                { url: 'img/FriendsPlus/Page69/E5/30.jpg'},
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page69/E5/31.jpg'},
            ],
            
        ],
        questions: [
          {
            title: 'The dictionaries are # the shelf',
            answer: ['table']
          },
        ]
      },


}

export default json;