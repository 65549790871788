import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'WB6-U7-P53-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page53/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 7,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page53/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/06.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page53/E1/07.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page53/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/14.jpg', input: 3, },
        { url: 'img/FriendsPlus/Page53/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/18.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page53/E1/19.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/22.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/23.jpg', input: 5, },
        { url: 'img/FriendsPlus/Page53/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/26.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/27.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page53/E1/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/31.jpg', input: 7, },
        { url: 'img/FriendsPlus/Page53/E1/32.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page53/E1/33.jpg' },
      ]

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P53-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page53/E2/Key/Key.png',
    // titleImage: 'img/FriendsPlus/Page51/E4/title.jpg',
    titleQuestion: [{ num: '2', title: ' Rewrite the sentences with punctuation.', color: '#00a850', star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<p>will smith is a famous american actor</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>   Will Smith is a famous American actor.</u></p>" +
          "<p>1  he lives in the city of los angeles</p><p style='font-weight:500'>#</p>" +
          "<p>2  hes interested in music golf and football</p><p style='font-weight:500'>#</p>" +
          "<p>3  hes got a daughter her name is willow</p><p style='font-weight:500'>#</p>" +
          "<p>4  i like will smith a lot</p><p style='font-weight:500'>#</p>" +
          "<p>5  do you like him too</p><p style='font-weight:500'>#</p>",
        answer: [
          "He lives in the city of Los Angeles.",
          "He's interested in music, golf and football.",
          "He's got a daughter. Her name is Willow.",
          "I like Will Smith a lot.",
          "Do you like him too?",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P53-E3',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page53/E3/Key/Key.png',
    // titleImage: 'img/FriendsPlus/Page51/E4/title.jpg',
    titleQuestion: [{ num: '3', title: 'Work in pairs. One makes questions. The other answers them. Then both complete the description of J.K.Rowling.', color: '#00a850', star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<p>1 Who is she?</p><p><span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'>She is a famous writer.</span> She is from Wales.</p>" +
          "<p style='font-weight:500'>2 # ?</p><p>She lives in Edinburgh, Scotland.</p>" +
          "<p style='font-weight:500'>3 # ?</p><p>She studied French and classics at university.</p>" +
          "<p style='font-weight:500'>4 # ?</p><p>She’s average height (one metre sixty-five).</p><p>She’s got blonde hair, blue eyes and a friendly face.</p>" +
          "<p style='font-weight:500'>5 # ?</p><p>She became a famous writer in 1997 when her first book <span style=' font-style: italic'> Harry Potter and the Philosopher’s Stone </span> became popular.</p>",
        answer: [
          "Where does she live",
          "What did she study at university",
          "What does she look like",
          "When did she become a famous writer? Why",
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P53-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    // titleImage: 'img/FriendsPlus/Page53/E4/1.jpg',
    titleQuestion: [{ num: '4', title: ' Work in pairs. Write questions and answers about a person you admire. Then make a description of him / her. Use the text in exercise 3 to help you.', color: '#00a850', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;